import React from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import MonthlyResourceRateCalc from './components/MonthlyResourceRateCalc';
import MonthlyRunRateResourceRequirements from './components/MonthlyRunRateResourceRequirements';

class MonthlyRunRate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      monthlyResults: this.props.dynamicTabState.MonthlyRunRate ? this.props.dynamicTabState.MonthlyRunRate.monthlyResults : [],
    };
  }

  mainBody = (props) => (
    <article>
      <MonthlyRunRateResourceRequirements
        {...props}
        currency={this.props.billingCurrency}
        hourlySelections={this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.hourlyResults ? this.props.dynamicTabState.RateLookUp.hourlyResults : []}
        changeMonthlyResults={(newMonthly) => this.changeMonthlyResults(newMonthly)}
        changeHourlyConstructed={(newHourly) => this.changeHourlyConstructed(newHourly)}
        updateMonthlyResults={(monthlyResults) => this.updateMonthlyResults(monthlyResults)}
      />
      <MonthlyResourceRateCalc {...props} showDownload hourlyConstructed={this.props.dynamicTabState.MonthlyRunRate ? this.props.dynamicTabState.MonthlyRunRate.hourlyConstructed : []} hourlySelections={this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.hourlyResults ? this.props.dynamicTabState.RateLookUp.hourlyResults : []} monthlyResults={this.state.monthlyResults} />
    </article>
  );

  updateMonthlyResults(monthlyResults) {
    this.setState({
      monthlyResults,
    });
  }

  changeMonthlyResults(monthlyResultsReceived) {
    this.props.changeDynamicTabState({ monthlyResults: monthlyResultsReceived }, 'MonthlyRunRate');
  }

  changeHourlyConstructed(hourlyResultsReceived) {
    this.props.changeDynamicTabState({ hourlyConstructed: hourlyResultsReceived }, 'MonthlyRunRate');
  }

  render() {
    return <div>{this.mainBody(this.props)}</div>;
  }
}

MonthlyRunRate.propTypes = {
  billingCurrency: PropTypes.string,
  dynamicTabState: PropTypes,
  changeDynamicTabState: PropTypes.func,
};

export default MonthlyRunRate;
