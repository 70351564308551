import React from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import HourlyResourceRateCalc from './components/HourlyResourceRateCalc';
import RateLookUpResourceRequirements from './components/RateLookUpResourceRequirements';

class RateLookUp extends React.Component {
  constructor(props) {
    super(props);
  }

  mainBody = (props) => (
    <article>
      <RateLookUpResourceRequirements {...props} changeDynamicTabState={this.changeDynamicTabState} />
      <HourlyResourceRateCalc {...props} grid="RateLookUp" showDownload hourlyResults={this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.hourlyResults ? this.props.dynamicTabState.RateLookUp.hourlyResults : []} />
    </article>
  )

  changeDynamicTabState = (keyToChange, valueToChange, toRunAfter) => {
    this.props.changeDynamicTabState({ [keyToChange]: valueToChange }, 'RateLookUp', toRunAfter);
  }

  render() {
    return <div>{this.mainBody(this.props)}</div>;
  }
}

RateLookUp.propTypes = {
  dynamicTabState: PropTypes,
  changeDynamicTabState: PropTypes.func,
};

export default RateLookUp;
