import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import Cookies from 'js-cookie';
import { readRemoteFile } from 'react-papaparse';
import * as constantImports from '../../constants';
import * as UtilsFunction from '../../utils';

const defaultSelectionsRolesListed = [
  {
    indexSelection: 1,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Agent',
    Billable: 'Yes',
    Level: 'B',
    SubLevel: '3',
    standardRatio: 'N/A',
    Ratio: '1',
    FTECount: 0,
  },
  {
    indexSelection: 2,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Sr. Agent',
    Billable: 'Yes',
    Level: 'D',
    SubLevel: '3',
    standardRatio: '1:15',
    FTECount: '',
    Ratio: 15,
  },
  {
    indexSelection: 3,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'QA',
    Billable: 'Yes',
    Level: 'D',
    SubLevel: '3',
    standardRatio: '1:25',
    FTECount: '',
    Ratio: 25,
  },
  {
    indexSelection: 4,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Operations Manager',
    Billable: 'No',
    Level: 'L',
    SubLevel: '3',
    standardRatio: '1:100',
    FTECount: '',
    Ratio: 100,
  },
  {
    indexSelection: 5,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Reporting Analyst',
    Billable: 'No',
    Level: 'D',
    SubLevel: '3',
    standardRatio: '1:100',
    FTECount: '',
    Ratio: 100,
  },
  {
    indexSelection: 6,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Trainer',
    Billable: 'No',
    Level: 'F',
    SubLevel: '3',
    standardRatio: '1:75',
    FTECount: '',
    Ratio: 75,
  },
  {
    indexSelection: 7,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'RTA',
    Billable: 'No',
    Level: 'F',
    SubLevel: '3',
    standardRatio: '1:75',
    FTECount: '',
    Ratio: 75,
  },
  {
    indexSelection: 8,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Team Lead',
    Billable: 'No',
    Level: 'H',
    SubLevel: '3',
    standardRatio: '1:15',
    FTECount: '',
    Ratio: 15,
  },
  {
    indexSelection: 9,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Project Manager',
    Billable: 'No',
    Level: 'J',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: '1 per site',
    FTECount: 1,
  },
  {
    indexSelection: 10,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Policy Manager',
    Billable: 'No',
    Level: 'J',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: '1 per site',
    FTECount: 1,
  },
  {
    indexSelection: 11,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Quality Manager',
    Billable: 'No',
    Level: 'J',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: '1 per site',
    FTECount: 1,
  },
  {
    indexSelection: 12,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Training Manager',
    Billable: 'No',
    Level: 'J',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: '1 per site',
    FTECount: 1,
  },
  {
    indexSelection: 13,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Global QTP Lead',
    Billable: 'No',
    Level: 'N',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: '1 Globally if applicable',
    FTECount: 1,
  },
  {
    indexSelection: 14,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Global Training Lead',
    Billable: 'No',
    Level: 'N',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: '1 Globally if applicable',
    FTECount: 1,
  },
  {
    indexSelection: 15,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Custom - 1',
    Billable: 'No',
    Level: 'B',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: 'N/A',
    FTECount: 0,
  },
  {
    indexSelection: 16,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Custom - 2',
    Billable: 'No',
    Level: 'L',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: 'N/A',
    FTECount: 0,
  },
  {
    indexSelection: 17,
    CommunicationTechnicalComplexityUplift: 'Yes',
    Role: 'Custom - 3',
    Billable: 'No',
    Level: 'L',
    SubLevel: '3',
    Ratio: 'N/A',
    standardRatio: 'N/A',
    FTECount: 0,
  },
];

const defaultSelections = [
  {
    indexSelection: 1, CommunicationTechnicalComplexityUplift: 'No', Overtime: 0, NightShift: 0, Weekend: 0, PublicHoliday: 0, Role: 'BLENDED',
  },
];

class BlendedResourceRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selections: defaultSelections,
      selectionsRolesListed: defaultSelectionsRolesListed,
      businessAreaRoleObject: {},
      locationPerLevelSubLevelBusinessArea: [],
      complexityMultipliersByLocation: [],
      locationPerLanguages: [],
    };
  }

  componentDidMount() {
    this.setUpSelectionObjects();
    this.setUpBusinessAreaRoleObject();
    this.setUpLocationPerLevelSubLevelBusinessArea();
    this.setComplexityMultipliersByLocation();
    this.setUpLocationPerLanguages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.communicationLevel !== this.props.communicationLevel || prevProps.technicalLevel !== this.props.technicalLevel) {
      if (this.state.selections[0].Location || this.state.selections[0].Language) {
        const selections = [...this.state.selections];
        selections[0].Location = '';
        selections[0].Language = '';
        this.setState({
          selections,
        });
      }
    }
    if (!_.isEqual(prevProps.billingCurrency, this.props.billingCurrency) || !_.isEqual(prevProps.calendarYear, this.props.calendarYear) || !_.isEqual(prevProps.communicationLevel, this.props.communicationLevel) || !_.isEqual(prevProps.desription, this.props.desription) || !_.isEqual(prevProps.includeSupernova, this.props.includeSupernova) || !_.isEqual(prevProps.includeWFM, this.props.includeWFM) || !_.isEqual(prevProps.programName, this.props.programName) || !_.isEqual(prevProps.resiliency, this.props.resiliency) || !_.isEqual(prevProps.technicalLevel, this.props.technicalLevel)) {
      this.props.resetHourlyMonthlyResults();
    } else if (!_.isEqual(this.state, prevState)) {
      this.props.resetHourlyMonthlyResults();
    }
  }

  handleInputChange(value, selectionIndex, property, objectToModify) {
    if (!value) {
      value = parseInt(0);
    }
    const newPropValue = {};
    newPropValue[property] = parseFloat(value);

    const objectSelection = [...this.state[objectToModify]];
    let validation = true;
    objectSelection.forEach((element) => {
      if (element.indexSelection === selectionIndex) {
        element[property] = value;

        const sum = Number(element.NightShift) + Number(element.Overtime) + Number(element.PublicHoliday) + Number(element.Weekend);
        if (sum > 100) {
          validation = false;
        }
      }
    });

    if (validation) {
      const stateSelection = {};
      stateSelection[objectToModify] = this.state[objectToModify].map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el));
      this.setState(stateSelection);

      if (selectionIndex >= 2 && selectionIndex <= 8 && property === 'Ratio') {
        const selectionsRolesListed = [...this.state[objectToModify]];
        selectionsRolesListed.forEach((element) => {
          if (element.indexSelection === selectionIndex) {
            let newFTEValue = parseFloat(selectionsRolesListed[0].FTECount) / parseFloat(element.Ratio);
            newFTEValue = (Number.isInteger(newFTEValue) ? newFTEValue : newFTEValue.toFixed(2));
            element.FTECount = (parseFloat(newFTEValue) === Infinity ? 0 : newFTEValue);
          }
        });
        this.setState({
          selectionsRolesListed,
        });
      }
    }
  }

  handleFTEInputChange(value, selectionIndex, property, objectToModify) {
    if (!value) {
      value = parseInt(0);
    }
    this.handleInputChange(value, selectionIndex, property, objectToModify);
    const newPropValue = {};
    newPropValue[property] = parseFloat(value);
    let stateSelection = {};
    if (selectionIndex === 1) {
      const selectionsRolesListed = [...this.state.selectionsRolesListed];
      selectionsRolesListed.forEach((element) => {
        if (element.indexSelection >= 2 && element.indexSelection <= 8) {
          let newFTEValue = parseFloat(selectionsRolesListed[0].FTECount) / parseFloat(element.Ratio);
          newFTEValue = (Number.isInteger(newFTEValue) ? newFTEValue : newFTEValue.toFixed(2));
          element.FTECount = parseFloat(newFTEValue) === Infinity ? 0 : newFTEValue;
        }
      });
      this.setState({ selectionsRolesListed });

      if (value > 0) {
        stateSelection.selections = this.state.selections.map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el));
        this.setState(stateSelection);
      }
      if (value <= 0) {
        stateSelection = [...this.state.selections];
        delete stateSelection[0].FTECount;
        this.setState(stateSelection);
      }
    }
    if (selectionIndex >= 2 && selectionIndex <= 8) {
      const selectionsRolesListed = [...this.state.selectionsRolesListed];
      selectionsRolesListed.forEach((element) => {
        if (element.indexSelection === selectionIndex) {
          let newRadioValue = parseFloat(selectionsRolesListed[0].FTECount) / parseFloat(element.FTECount);
          newRadioValue = (Number.isInteger(newRadioValue) ? newRadioValue : newRadioValue.toFixed(2));
          element.Ratio = (parseFloat(newRadioValue) === Infinity) ? 0 : newRadioValue;
        }
      });
      this.setState({
        selectionsRolesListed,
      });
    }
  }

  onDropdownChange(valuePassed, selectionIndex, property, objectToModify) {
    const newPropValue = {};
    newPropValue[property] = valuePassed;
    const stateSelection = {};
    stateSelection[objectToModify] = this.state[objectToModify].map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el));
    this.setState(stateSelection);
  }

  onRadioChange(property, selectionIndex, objectToModify) {
    const newPropValue = {};
    const originalObject = this.state[objectToModify].filter((el) => (el.indexSelection === selectionIndex))[0];
    newPropValue[property] = (originalObject[property] === 'No' ? 'Yes' : 'No');
    const stateSelection = {};
    stateSelection[objectToModify] = this.state[objectToModify].map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el));
    this.setState(stateSelection);
  }

  setUpSelectionObjects() {
    const newPropValue = {};
    newPropValue.ProgramName = this.props[UtilsFunction.lowerFirstLetter('ProgramName')];

    this.setState({
      selections: this.state.selections.map((el) => ({ ...el, ...newPropValue })),
    });
  }

  setUpLocationPerLevelSubLevelBusinessArea() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Base%20Rates%20by%20Level%20and%20Location.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        this.setState({ locationPerLevelSubLevelBusinessArea: results.data });
      },
    });
  }

  setUpLocationPerLanguages() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Language%20Tier%20by%20Location.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        this.setState({ locationPerLanguages: results.data });
      },
    });
  }

  getLanguagePerLocation(location) {
    const resultArray = [];
    let locationIndex = -1;
    for (let i = 1; i < this.state.locationPerLanguages[0].length; i += 1) {
      if (this.state.locationPerLanguages[0][i] === location) {
        locationIndex = i;
      }
    }

    if (locationIndex > 0) {
      for (let i = 1; i < this.state.locationPerLanguages.length; i += 1) {
        if ((this.state.locationPerLanguages[i][locationIndex] !== '') && (this.state.locationPerLanguages[i][locationIndex] !== undefined)) {
          resultArray.push(this.state.locationPerLanguages[i][0]);
        }
      }
    }

    return resultArray;
  }

  setComplexityMultipliersByLocation() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Complexity%20Multipliers%20by%20Location.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        this.setState({ complexityMultipliersByLocation: results.data.filter((itemFiltered) => itemFiltered.length > 1) });
      },
    });
  }

  getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(level, sublevel, businessArea, communicationWorkComplexity, domainTechExpertise) {
    return UtilsFunction.getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(level, sublevel, businessArea, communicationWorkComplexity, domainTechExpertise, this.state.locationPerLevelSubLevelBusinessArea, this.state.complexityMultipliersByLocation);
  }

  setUpBusinessAreaRoleObject() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Role%20by%20Business%20Area.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        const businessAreaRoleObject = {};
        for (let i = 0; i < results.data.length; i += 1) {
          if (businessAreaRoleObject[results.data[i][0]]) {
            businessAreaRoleObject[results.data[i][0]].push({
              roleName: results.data[i][1],
              Level: results.data[i][2],
              SubLevel: results.data[i][3],
            });
          } else {
            businessAreaRoleObject[results.data[i][0]] = [];
            businessAreaRoleObject[results.data[i][0]].push({
              roleName: results.data[i][1],
              Level: results.data[i][2],
              SubLevel: results.data[i][3],
            });
          }
        }
        this.setState({ businessAreaRoleObject });
      },
    });
  }

  getSelection(selectionObject) {
    return this.state.businessAreaRoleObject[selectionObject.ProgramName].filter((objIter) => objIter.roleName === 'RTA')[0];
  }

  mainBody = () => (
    <div>
      <div>
        <h4 className="c_blue f_bold t_upper t_underline">Resource Requirements</h4>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>Location</th>
              <th>Language</th>
              <th>Overtime (%)</th>
              <th>Night Shift (%)</th>
              <th>Weekend (%)</th>
              <th>Public Holidays (%)</th>
              <th>Standard Shift (%)</th>
            </tr>
          </thead>
          <tbody>
            {this.state.selections.map((selectionObject, selectionIndex) => (
              <tr>
                <td>
                  <form>
                    <select value={selectionObject.Location} onChange={(e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Location', 'selections')}>
                      <option value="selectOne">select one</option>
                      {this.state.businessAreaRoleObject[selectionObject.ProgramName] && this.getSelection(selectionObject) && this.getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(
                        this.state.businessAreaRoleObject[selectionObject.ProgramName] && this.getSelection(selectionObject) && this.getSelection(selectionObject).Level,
                        this.state.businessAreaRoleObject[selectionObject.ProgramName] && this.getSelection(selectionObject) && this.getSelection(selectionObject).SubLevel,
                        selectionObject.ProgramName,

                        this.props.communicationLevel,

                        this.props.technicalLevel,
                      ).map((locationOption, locationOptionIndex) => (
                        <option value={locationOption}>
                          {locationOption}
                        </option>
                      ))}
                    </select>
                  </form>
                </td>
                <td>
                  <form>
                    <select value={selectionObject.Language} onChange={(e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Language', 'selections')}>
                      <option value="selectOne">select one</option>
                      {selectionObject.Location && this.getLanguagePerLocation(selectionObject.Location).map((languageOption, languageOptionIndex) => (
                        <option value={languageOption}>
                          {languageOption}
                        </option>
                      ))}
                    </select>
                  </form>
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.Overtime || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'Overtime', 'selections')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.NightShift || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'NightShift', 'selections')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.Weekend || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'Weekend', 'selections')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.PublicHoliday || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'PublicHoliday', 'selections')} />
                </td>
                <td>
                  {100 - (selectionObject.Overtime || 0) - (selectionObject.NightShift || 0) - (selectionObject.Weekend || 0) - (selectionObject.PublicHoliday || 0)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="top_space_added">
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Role</th>
              <th>Billable</th>
              <th>Level</th>
              <th>Sub-Level</th>
              <th>Complexity Cost Incuded</th>
              <th>Standard Ratio</th>
              <th>Ratio of Role to Agents, 1:X (can be adjusted)</th>
              <th>Number of FTEs</th>
            </tr>
          </thead>
          <tbody>
            {this.state.selectionsRolesListed.map((selectionObject, selectionIndex) => (
              <tr>
                <td>
                  {selectionIndex + 1}
                </td>
                <td>
                  {selectionObject.Role}
                </td>
                <td>
                  <form>
                    <select value={selectionObject.Billable} onChange={(e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Billable', 'selectionsRolesListed')}>
                      <option value="">select one</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </form>
                </td>
                <td>
                  {(selectionObject.Role.includes('Custom')) ? (
                    <form>
                      <select value={selectionObject.Level} onChange={(e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Level', 'selectionsRolesListed')}>
                        <option value="">select one</option>
                        <option value="B">B</option>
                        <option value="L">L</option>
                        <option value="J">J</option>
                        <option value="H">H</option>
                        <option value="F">F</option>
                        <option value="D">D</option>
                      </select>
                    </form>
                  ) : selectionObject.Level}
                </td>
                <td>
                  {(selectionObject.SubLevel && !selectionObject.Role.includes('Custom')) ? (selectionObject.SubLevel) : (selectionObject.SubLevel)}
                </td>
                <td>
                  <input type="checkbox" checked={selectionObject.CommunicationTechnicalComplexityUplift === 'Yes'} onChange={() => this.onRadioChange('CommunicationTechnicalComplexityUplift', selectionObject.indexSelection, 'selectionsRolesListed')} />
                </td>
                <td>
                  {selectionObject.standardRatio}
                </td>
                <td>
                  {(selectionObject.Ratio || selectionObject.Ratio === 0) && (selectionIndex >= 1 && selectionIndex <= 7) ? (
                    <input type="number" min="0" max="10000" value={selectionObject.Ratio || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'Ratio', 'selectionsRolesListed')} />
                  ) : selectionObject.Ratio}
                </td>
                <td>
                  <input type="number" min="0" max="10000" value={selectionObject.FTECount || 0} onChange={(e) => this.handleFTEInputChange(e.target.value, selectionObject.indexSelection, 'FTECount', 'selectionsRolesListed')} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="flex flex_end top_space_added">
        <div className="flex flex_space_between">
          <div>
            <button className="blue_button clear_all_button" type="button" title={constantImports.TOOL_TIP_MSG.clearAll} onClick={() => this.clearAllPressed()}>
              <span>Clear All</span>
            </button>
          </div>
          <div>
            <button className="blue_button" type="button" title={constantImports.TOOL_TIP_MSG.submit} onClick={() => this.submitPressed()}>
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  submitPressed() {
    let allFieldsProvided = true;
    constantImports.ALL_ROW_REQUIRED.forEach((requiredAllRow) => {
      if (!Object.keys(this.props).includes(UtilsFunction.lowerFirstLetter(requiredAllRow))) {
        allFieldsProvided = false;
      }
    });
    this.state.selections.forEach((selection, indexOfSelection) => {
      constantImports.BLENDED_ALL_ROW_REQUIRED.forEach((requiredAllRow) => {
        if (!Object.keys(selection).includes(requiredAllRow)) {
          allFieldsProvided = false;
        }
      });
    });
    this.state.selectionsRolesListed.forEach((selection, indexOfSelection) => {
      constantImports.BLENDED_PER_ROW_REQUIRED.forEach((requiredPerRow) => {
        if (!Object.keys(selection).includes(requiredPerRow)) {
          allFieldsProvided = false;
        }
      });
    });
    if (allFieldsProvided) {
      const allObjectToAttach = {};
      allObjectToAttach.Location = this.state.selections[0].Location;
      allObjectToAttach.Language = this.state.selections[0].Language;
      allObjectToAttach.Overtime = `${this.state.selections[0].Overtime}%`;
      allObjectToAttach.NightShift = `${this.state.selections[0].NightShift}%`;
      allObjectToAttach.Weekend = `${this.state.selections[0].Weekend}%`;
      allObjectToAttach.PublicHoliday = `${this.state.selections[0].PublicHoliday}%`;
      allObjectToAttach.billingCurrency = this.props.billingCurrency;
      allObjectToAttach.calendarYear = this.props.calendarYear;
      allObjectToAttach.CommunicationLevel = this.props.communicationLevel;
      allObjectToAttach.TechnicalLevel = this.props.technicalLevel;
      allObjectToAttach.Resiliency = this.props.resiliency;
      allObjectToAttach.IncludeSupernova = this.props.includeSupernova;
      allObjectToAttach.IncludeWFM = this.props.includeWFM;

      let bodyObjectToAttach = this.state.selectionsRolesListed.map((el) => {
        const newObjectToAttach = { ...el };
        for (const property in allObjectToAttach) {
          if (property === 'Ratio') {
            newObjectToAttach[property] = allObjectToAttach[property];
          } else {
            newObjectToAttach[property] = allObjectToAttach[property];
          }
        }
        return newObjectToAttach;
      });

      bodyObjectToAttach = bodyObjectToAttach.map((bodyObjectItem, index) => {
        if (!bodyObjectItem.FTECount) {
          bodyObjectItem.FTECount = 0;
        }
        if (index >= 1 && index <= 7) {
          bodyObjectItem.Ratio = '';
        }
        bodyObjectItem.Ratio = bodyObjectItem.Ratio.toString();
        bodyObjectItem.FTECount = bodyObjectItem.FTECount.toString();

        delete bodyObjectItem.indexSelection;
        delete bodyObjectItem.standardRatio;
        return bodyObjectItem;
      });

      const requestOptions = {
        method: 'POST',
        headers: constantImports.amazonHeader(this.props.idToken),
        body: JSON.stringify({
          FormInput: bodyObjectToAttach,
          UserData: {
            UserName: this.props.preferredEmail,
          },
        }),
      };
      this.props.loadingSet(() => fetch(`${constantImports.AMAZON_API_BASE}/blended-rate-calculation`, requestOptions).then((response) => {
        if (response.status !== 200) {
          this.props.loadingSet(() => null);
          alert('No information found with those values, or an error occurred.');
        } else {
          response.json().then((data) => {
            this.props.changeHourlyMonthlyResults(data);
            this.props.changeBlendedResults(this.state.selections, this.state.selectionsRolesListed);
            this.props.loadingSet(() => null);
          });
        }
      }));
    } else {
      alert('All required fields were not provided');
    }
  }

  clearAllPressed() {
    const clearFTCount = [...this.state.selectionsRolesListed];
    clearFTCount[0].FTECount = 0;
    this.setState({
      selections: defaultSelections,
      selectionsRolesListed: clearFTCount,
    }, () => { this.setUpSelectionObjects(); });
  }

  render() {
    return <div>{this.mainBody()}</div>;
  }
}

BlendedResourceRequirements.propTypes = {
  billingCurrency: PropTypes.string,
  calendarYear: PropTypes.string,
  desription: PropTypes.string,
  programName: PropTypes.string,
  communicationLevel: PropTypes.string,
  technicalLevel: PropTypes.string,
  resiliency: PropTypes.string,
  includeSupernova: PropTypes.string,
  includeWFM: PropTypes.string,
  resetHourlyMonthlyResults: PropTypes.func,
  loadingSet: PropTypes.func,
  changeHourlyMonthlyResults: PropTypes.func,
  changeBlendedResults: PropTypes.func,
  preferredEmail: PropTypes.string,
  idToken: PropTypes.string,
};

export default BlendedResourceRequirements;
