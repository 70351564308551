import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import '../../App.css';
import * as constantImports from '../../constants';
import * as UtilsFunction from '../../utils';

class MonthlyRunRateResourceRequirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hourlySelections: [],
      Currency: constantImports.CURRENCY_MAPPING,
      // CurrencyBilling: this.props.billingCurrency,
    };
  }

  componentDidMount() {
    this.setUpSelectionObjects();
  }

  componentDidUpdate(prevProps) {
    if (!UtilsFunction.isArrayEqual(prevProps.hourlySelections, this.props.hourlySelections)) {
      this.setUpSelectionObjects();
    }
  }

  handleInputChange(value, selectionIndex, property) {
    const newPropValue = {};
    newPropValue[property] = value;

    const hourlySelectionsNew = this.state.hourlySelections.map((item) => ({ ...item }));
    let validation = true;
    hourlySelectionsNew.forEach((element) => {
      if (element.indexSelection === selectionIndex) {
        element[property] = value;

        const sum = Number(element.NightShift) + Number(element.Overtime) + Number(element.PublicHoliday) + Number(element.Weekend);
        if (sum > 100) {
          validation = false;
        }
      }
    });

    if (validation) {
      this.props.dynamicTabState.MonthlyRunRate = null;
      this.props.updateMonthlyResults([]);
      this.setState({
        hourlySelections: this.state.hourlySelections.map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el)),
      });
    }
  }

  setUpSelectionObjects() {
    this.setState({
      hourlySelections: this.props.hourlySelections.map((selectionObject, selectionObjectIndex) => ({
        ...selectionObject,
        ...{
          indexSelection: selectionObjectIndex, Overtime: 0, NightShift: 0, Weekend: 0, PublicHoliday: 0,
        },
      })),
    });
  }

  mainBody = () => (
    <div className="top_margin">
      <div>
        <h4 className="c_blue f_bold t_upper t_underline">Resource Requirements</h4>
      </div>
      <div>
        <p>For each of the defined roles, please indicate the total FTE count as well as the mix of overtime, night shift, weekend, and public holidays expected.</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              <th>Role Name/Description</th>
              <th>Standard Hourly Rate per FTE</th>
              <th>FTE Count</th>
              <th>Overtime (%)</th>
              <th>Night Shift (%)</th>
              <th>Weekend (%)</th>
              <th>Public Holidays (%)</th>
              <th>Standard Shift (%)</th>
            </tr>
          </thead>
          <tbody>
            {this.state.hourlySelections.map((selectionObject, selectionIndex) => (
              <tr>
                <td>{selectionIndex + 1}</td>
                <td>{selectionObject.Role}</td>
                <td>{UtilsFunction.formatDollarValue(selectionObject.StandardHourlyRatePerFTE, this.state.Currency[this.props.currency])}</td>
                <td>
                  <input type="number" min="0" max="5000" value={selectionObject.FTECount || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'FTECount')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.Overtime || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'Overtime')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.NightShift || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'NightShift')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.Weekend || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'Weekend')} />
                </td>
                <td>
                  <input type="number" min="0" max="100" value={selectionObject.PublicHoliday || 0} onChange={(e) => this.handleInputChange(e.target.value, selectionObject.indexSelection, 'PublicHoliday')} />
                </td>
                <td>
                  {100 - (selectionObject.Overtime || 0) - (selectionObject.NightShift || 0) - (selectionObject.Weekend || 0) - (selectionObject.PublicHoliday || 0)}
                  %
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="top_space_added flex flex_end">
        <div className="flex flex_space_between">
          <div>
            <button className="blue_button clear_all_button" type="button" title={constantImports.TOOL_TIP_MSG.clearAll} onClick={() => this.clearAllPressed()}>
              <span>Clear All</span>
            </button>
          </div>
          <div>
            <button className="blue_button" type="button" title={constantImports.TOOL_TIP_MSG.submit} onClick={() => this.submitPressed()}>
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  clearAllPressed() {
    this.setState({
      hourlySelections: [],
    }, () => { this.setUpSelectionObjects(); });
  }

  submitPressed() {
    let allFieldsProvided = true;
    const hourlySelectionBacked = this.state.hourlySelections;
    hourlySelectionBacked.forEach((selection, indexOfSelection) => {
      constantImports.MONTHLY_RESOURCE_REQUIRED.forEach((requiredMonthlyRow) => {
        if (!Object.keys(selection).includes(requiredMonthlyRow)) {
          allFieldsProvided = false;
        }
      });
    });

    if (allFieldsProvided) {
      const requestOptions = {
        method: 'POST',
        headers: constantImports.amazonHeader(this.props.idToken),
        body: JSON.stringify({
          FormInput: hourlySelectionBacked.map((selectionObject) => {
            const {
              indexSelection, ComplexityUplift, BillableHours, LanguageTierUplift, LocationBaseRate, Resiliency, StandardHourlyRatePerFTE, SuperNova, Units, WFM, Year, ...excludedObject
            } = selectionObject;
            delete excludedObject['#'];
            excludedObject.CommunicationTechnicalComplexityUplift = selectionObject.ComplexityUplift === 0 ? 'No' : 'Yes';
            excludedObject.ProgramName = this.props.programName;
            excludedObject.Desription = this.props.desription;
            excludedObject.Overtime += '%';
            excludedObject.NightShift += '%';
            excludedObject.Weekend += '%';
            excludedObject.PublicHoliday += '%';
            return excludedObject;
          }),
          UserData: {
            UserName: this.props.preferredEmail,
          },
        }),
      };
      this.props.loadingSet(() => fetch(`${constantImports.AMAZON_API_BASE}/monthly-run-rate`, requestOptions).then((response) => {
        if (response.status !== 200) {
          this.props.loadingSet(() => null);
          alert('No information found with those values, or an error occurred.');
        } else {
          response.json().then((data) => {
            this.props.updateMonthlyResults(data);
            this.props.changeMonthlyResults(data);
            this.props.changeHourlyConstructed(hourlySelectionBacked);
            this.props.loadingSet(() => null);
          });
        }
      }));
    } else {
      alert('All required fields were not provided');
    }
  }

  render() {
    return <div>{this.mainBody()}</div>;
  }
}
MonthlyRunRateResourceRequirements.propTypes = {
  hourlySelections: PropTypes,
  programName: PropTypes.string,
  desription: PropTypes.string,
  loadingSet: PropTypes.func,
  changeMonthlyResults: PropTypes.func,
  changeHourlyConstructed: PropTypes.func,
  preferredEmail: PropTypes.string,
  idToken: PropTypes.string,
  dynamicTabState: PropTypes,
  updateMonthlyResults: PropTypes.func,
  currency: PropTypes.string,
};

export default MonthlyRunRateResourceRequirements;
