import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import ExportFunc from '../../downloadFunc';
import * as constantImports from '../../constants';
import * as UtilsFunction from '../../utils';

class BlendedResourceRateDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  downloadClicked = () => {
    let desription = this.props.desription;
    const quote = '"';
    const singlequote = "'";

    if (desription.charAt(0) === '=' || desription.charAt(0) === '+' || desription.charAt(0) === '-' || desription.charAt(0) === '@' || desription.charAt(0) === '\t' || desription.charAt(0) === '\n') {
      desription = singlequote + desription;
    }

    desription = desription.replaceAll(quote, '""');
    desription = quote + desription + quote;

    this.downloadFunction(desription);
  }

  downloadFunction = (desription) => {
    const paramsFormObj = {
      ProgramName: this.props.programName,
      Desription: desription,
      billingCurrency: this.props.billingCurrency,
      calendarYear: this.props.calendarYear,
      CommunicationLevel: this.props.communicationLevel,
      TechnicalLevel: this.props.technicalLevel,
      Resiliency: this.props.resiliency,
      IncludeSupernova: this.props.includeSupernova,
      IncludeWFM: this.props.includeWFM,
      RSNames: ['', '', ''],
      Locations: ['', '', ''],
      Levels: ['', '', ''],
      SubLevels: ['', '', ''],
      CommunicationTechnicalComplexityUplifts: ['Yes', 'Yes', 'Yes'],
      Languages: ['', '', ''],
    };

    const blendParamsObj = {
      Location: this.props.blendedResults.selections[0].Location,
      Language: this.props.blendedResults.selections[0].Language,
      Overtime: '0%',
      NightShift: '0%',
      Weekend: '0%',
      PublicHolidays: '0%',
      DetailInfo: [],
    };

    const emptyBlendTop = [
      {
        FTECount2: '',
        Overtime: `${this.props.blendedResults.selections[0].Overtime}%`,
        NightShift: `${this.props.blendedResults.selections[0].NightShift}%`,
        Weekend: `${this.props.blendedResults.selections[0].Weekend}%`,
        PublicHolidays: `${this.props.blendedResults.selections[0].PublicHoliday}%`,
        StandardShift: `${100 - this.props.blendedResults.selections[0].Overtime - this.props.blendedResults.selections[0].NightShift - this.props.blendedResults.selections[0].Weekend - this.props.blendedResults.selections[0].PublicHoliday}%`,
      },
    ];
    const blendSubRes = this.props.hourlyResults;
    const blendMonthSubRes = this.props.monthlyResults;
    const blendRateCal = [];
    this.props.blendedResults.selectionsRolesListed.forEach((selectionsRolesObject) => {
      const {
        FTECount, Ratio, indexSelection, standardRatio, ...excludedObject
      } = selectionsRolesObject;
      excludedObject.Location = 'Bangalore';
      excludedObject.Langage = 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)';
      excludedObject.Overtime = '0%';
      excludedObject.NightShift = '0%';
      excludedObject.Weekend = '0%';
      excludedObject.PublicHolidays = '0%';
      excludedObject.StandardShift = '0%';
      excludedObject.StandardRatio = selectionsRolesObject.standardRatio;
      blendRateCal.push(excludedObject);
    });
    const Currency = constantImports.CURRENCY_MAPPING[this.props.billingCurrency] || this.props.billingCurrency;
    const blendTotalFTES = this.props.totals.TotalFTES;
    const blendTotalBillableResources = this.props.totals.TotalBillableResources;
    const blendTotalEstimated = UtilsFunction.formatDollarValue(`${this.props.totals.TotalEstimatedMonthlyRunRate}`, 'M');
    const blendImpliedBlendedRate = UtilsFunction.formatDollarValue(`${this.props.totals.ImpliedBlendedRatePerFTEPerHour}`, '');
    ExportFunc.blendDownload(paramsFormObj, blendParamsObj, emptyBlendTop, blendSubRes, blendMonthSubRes, blendRateCal, Currency, blendTotalFTES, blendTotalBillableResources, blendTotalEstimated, blendImpliedBlendedRate);
  }

  mainBody = () => (
    <div>
      <div className="top_space_added flex flex_end">
        <div className="flex flex_space_between">
          <div>
            <button className="blue_button" type="button" title={constantImports.TOOL_TIP_MSG.downloadBtn} disabled={this.props.monthlyResults.length === 0} onClick={() => this.downloadClicked()}>
              <span>Download Results</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  render() {
    return <div>{this.mainBody()}</div>;
  }
}

BlendedResourceRateDownload.propTypes = {
  programName: PropTypes.string,
  desription: PropTypes.string,
  billingCurrency: PropTypes.string,
  calendarYear: PropTypes.string,
  communicationLevel: PropTypes.string,
  technicalLevel: PropTypes.string,
  resiliency: PropTypes.string,
  includeSupernova: PropTypes.string,
  includeWFM: PropTypes.string,
  blendedResults: PropTypes,
  hourlyResults: PropTypes,
  monthlyResults: PropTypes,
  totals: PropTypes.shape,
  ImpliedBlendedRatePerFTEPerHour: PropTypes.string,
};

export default BlendedResourceRateDownload;
