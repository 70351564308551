export const AREA_NAME = {
  em: '',
  ContReview: 'Content Review',
  Pso: 'PSO',
  Market: 'Marketing',
  Consult: 'Consulting',
  Infra: 'Infrastructure',
  HrO: 'HR Operations',
  Legal: 'Legal',
  FAO: 'Finance & Accounting Outsourcing',
};
