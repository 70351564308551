import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import ExportFunc from '../../downloadFunc';
import * as constantImports from '../../constants';
import * as UtilsFunction from '../../utils';

function fetchColumns(programName) {
  return programName ? constantImports.initialSelectionsData[programName].hourlyRateCalcColumn : constantImports.initialSelectionsData['Content Review'].hourlyRateCalcColumn;
}

function formColString(programName) {
  let stringToForm = '';
  fetchColumns(programName).forEach((colLet, colLetIndex) => {
    stringToForm += (colLet + (colLetIndex !== fetchColumns(programName).length - 1 ? '+' : ''));
  });
  return stringToForm;
}

class HourlyResourceRateCalc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Currency: constantImports.CURRENCY_MAPPING,
    };
  }

  downloadClicked = () => {
    let desription = this.props.desription;
    const quote = '"';
    const singlequote = "'";

    if (desription.charAt(0) === '=' || desription.charAt(0) === '+' || desription.charAt(0) === '-' || desription.charAt(0) === '@' || desription.charAt(0) === '\t' || desription.charAt(0) === '\n') {
      desription = singlequote + desription;
    }

    desription = desription.replaceAll(quote, '""');
    desription = quote + desription + quote;

    this.downloadFunction(desription);
  }

  downloadFunction = (desription) => {
    const paramsDonFormObj = {
      ProgramName: this.props.programName,
      Desription: desription,
      billingCurrency: this.props.billingCurrency,
      calendarYear: this.props.calendarYear,
      CommunicationLevel: this.props.communicationLevel,
      TechnicalLevel: this.props.technicalLevel,
      Resiliency: this.props.resiliency,
      IncludeSupernova: this.props.includeSupernova,
      IncludeWFM: this.props.includeWFM,
      RSNames: this.props.hourlyResults.map((hourlyResultObject) => hourlyResultObject.Role),
      Locations: this.props.hourlyResults.map((hourlyResultObject) => hourlyResultObject.Location),
      Levels: this.props.hourlyResults.map((hourlyResultObject) => hourlyResultObject.Level),
      SubLevels: this.props.hourlyResults.map((hourlyResultObject) => hourlyResultObject.SubLevel),
      CommunicationTechnicalComplexityUplifts: this.props.hourlyResults.map((hourlyResultObject) => 'Yes'),
      Languages: this.props.hourlyResults.map((hourlyResultObject) => hourlyResultObject.Language),
    };
    const estResult = this.props.hourlyResults.map((hourlyResultObject) => {
      const { Level, SubLevel, ...excludedObject } = hourlyResultObject;
      excludedObject.StandardShift = '100%';
      excludedObject.FTECount2 = '';
      excludedObject.Overtime = '0%';
      excludedObject.NightShift = '0%';
      excludedObject.Weekend = '0%';
      excludedObject.PublicHolidays = '0%';
      return excludedObject;
    });
    const currentColShow = constantImports.CURRENT_COL_SHOW;
    const Currency = constantImports.CURRENCY_MAPPING[this.props.billingCurrency] || this.props.billingCurrency;

    ExportFunc.rateupDownload(paramsDonFormObj, estResult, currentColShow, Currency);
  }

  mainBody = () => (
    <div className="top_margin">
      <div>
        <h4 className="c_blue f_bold t_upper t_underline">Hourly Resource Rate Calculation</h4>
      </div>
      <div>
        <p>Based on the program and role characteristics, the cost per FTE hour for each role is calculated as follows:</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th />
              <th />
              {this.props.hourlyResults && this.props.hourlyResults[0] && this.props.hourlyResults[0].Location && <th />}
              {this.props.hourlyResults && this.props.hourlyResults[0] && this.props.hourlyResults[0].Language && <th />}
              <th />
              {(this.props.grid === 'RateLookUp') ? <th /> : ''}
              <th className="right_border_table" />
              {fetchColumns(this.props.programName).map((colLet) => <th>{colLet}</th>)}
              <th className="left_border_table">
                {`H = ${formColString(this.props.programName)}`}
              </th>
            </tr>
            <tr>
              <th>#</th>
              <th>Role</th>
              {this.props.hourlyResults && this.props.hourlyResults[0] && this.props.hourlyResults[0].Location && <th>Location</th>}
              {this.props.hourlyResults && this.props.hourlyResults[0] && this.props.hourlyResults[0].Language && <th>Language</th>}
              <th>Year</th>
              <th>Billable Units</th>
              {(this.props.grid === 'RateLookUp') ? <th className="right_border_table">Units</th> : ''}
              {fetchColumns(this.props.programName).map((colLet) => <th>{constantImports.hourlyRateCalcColumnAttributes.filter((att) => att.letter === colLet)[0].title}</th>)}
              <th className="left_border_table">Standard Rate per FTE</th>
            </tr>
          </thead>
          <tbody>
            {this.props.hourlyResults.map((row) => (
              <tr>
                <td>{row['#']}</td>
                <td>{row.Role}</td>
                {row.Location && <td>{row.Location}</td>}
                {row.Language && <td>{row.Language}</td>}
                <td>{row.Year}</td>
                <td>{row.BillableHours.toLocaleString()}</td>
                {(this.props.grid === 'RateLookUp') ? <td className="right_border_table">{row.Units}</td> : ''}
                {fetchColumns(this.props.programName).map((eachRow) => <td>{UtilsFunction.formatDollarValue(`${row[constantImports.hourlyRateCalcColumnAttributes.filter((att) => att.letter === eachRow)[0].rowKey]}`, this.state.Currency[this.props.billingCurrency])}</td>)}
                <td className="left_border_table">{UtilsFunction.formatDollarValue(`${row.StandardHourlyRatePerFTE}`, this.state.Currency[this.props.billingCurrency])}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {this.props.showDownload && (
      <div className="top_space_added flex flex_end">
        <div className="flex flex_space_between">
          <div>
            <button className="blue_button" type="button" title={constantImports.TOOL_TIP_MSG.downloadBtn} disabled={this.props.hourlyResults.length === 0} onClick={() => this.downloadClicked()}>
              <span>Download Results</span>
            </button>
          </div>
        </div>
      </div>
      )}
    </div>
  )

  render() {
    return <div>{this.mainBody()}</div>;
  }
}

HourlyResourceRateCalc.propTypes = {
  billingCurrency: PropTypes.string,
  calendarYear: PropTypes.string,
  programName: PropTypes.string,
  desription: PropTypes.string,
  communicationLevel: PropTypes.string,
  technicalLevel: PropTypes.string,
  resiliency: PropTypes.string,
  includeSupernova: PropTypes.string,
  includeWFM: PropTypes.string,
  hourlyResults: PropTypes,
  showDownload: PropTypes.string,
  grid: PropTypes.string,
};

export default HourlyResourceRateCalc;
