import React from 'react';
import PropTypes from 'prop-types';
import '../../App.css';
import ExportFunc from '../../downloadFunc';
import * as constantImports from '../../constants';
import * as UtilsFunction from '../../utils';

class MonthlyResourceRateCalc extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Currency: constantImports.CURRENCY_MAPPING,
    };
  }

  downloadClicked = () => {
    let desription = this.props.desription;
    const quote = '"';
    const singlequote = "'";

    if (desription.charAt(0) === '=' || desription.charAt(0) === '+' || desription.charAt(0) === '-' || desription.charAt(0) === '@' || desription.charAt(0) === '\t' || desription.charAt(0) === '\n') {
      desription = singlequote + desription;
    }

    desription = desription.replaceAll(quote, '""');
    desription = quote + desription + quote;

    this.downloadFunction(desription);
  }

  downloadFunction = (desription) => {
    const paramsDonFormObj = {
      ProgramName: this.props.programName,
      Desription: desription,
      billingCurrency: this.props.billingCurrency,
      calendarYear: this.props.calendarYear,
      CommunicationLevel: this.props.communicationLevel,
      TechnicalLevel: this.props.technicalLevel,
      Resiliency: this.props.resiliency,
      IncludeSupernova: this.props.includeSupernova,
      IncludeWFM: this.props.includeWFM,
      RSNames: this.props.hourlyConstructed.map((hourlyResultObject) => hourlyResultObject.RoleNameDescription),
      Locations: this.props.hourlyConstructed.map((hourlyResultObject) => hourlyResultObject.Location),
      Levels: this.props.hourlyConstructed.map((hourlyResultObject) => hourlyResultObject.Level),
      SubLevels: this.props.hourlyConstructed.map((hourlyResultObject) => hourlyResultObject.SubLevel),
      CommunicationTechnicalComplexityUplifts: this.props.hourlyConstructed.map((hourlyResultObject) => 'Yes'),
      Languages: this.props.hourlyConstructed.map((hourlyResultObject) => hourlyResultObject.Language),
    };
    const estTab2Result = this.props.monthlyResults.filter((monthlyObject) => !('TotalFTES' in monthlyObject));
    const estResult = [];
    this.props.hourlyConstructed.forEach((hourlyResultObject) => {
      const { Level, SubLevel, ...excludedObject } = hourlyResultObject;
      const monthlyObject = this.props.monthlyResults.filter((toFilterObject) => toFilterObject['#'] === hourlyResultObject['#'])[0];
      const hourlyObject = this.props.hourlyConstructed.filter((toFilterObject) => toFilterObject['#'] === hourlyResultObject['#'])[0];
      excludedObject.FTECount2 = monthlyObject.FTECount;
      excludedObject.StandardShift = `${100 - hourlyObject.Overtime - hourlyObject.NightShift - hourlyObject.Weekend - hourlyObject.PublicHoliday}%`;
      excludedObject.Overtime = `${hourlyObject.Overtime}%`;
      excludedObject.NightShift = `${hourlyObject.NightShift}%`;
      excludedObject.Weekend = `${hourlyObject.Weekend}%`;
      excludedObject.PublicHolidays = `${hourlyObject.PublicHoliday}%`;
      estResult.push(excludedObject);
    });
    const Currency = constantImports.CURRENCY_MAPPING[this.props.billingCurrency] || this.props.billingCurrency;
    const filterObject = this.props.monthlyResults.filter((monthlyObject) => ('TotalFTES' in monthlyObject))[0];
    const estTotalFTES = filterObject.TotalFTES;
    const estTotalEstimated = UtilsFunction.formatDollarValue(`${filterObject.TotalEstimatedMonthlyRunRate}`, 'M');

    ExportFunc.monthlyRateDownload(paramsDonFormObj, estTab2Result, estResult, Currency, estTotalFTES, estTotalEstimated);
  }

  mainBody = () => {
    let showLast = true;
    if (this.props.monthlyResults[this.props.monthlyResults.length - 1] && this.props.monthlyResults[this.props.monthlyResults.length - 1].TotalEstimatedMonthlyRunRate) {
      showLast = false;
    }
    const totalsComputed = this.props.totals || this.props.monthlyResults[this.props.monthlyResults.length - 1];
    return (
      <div className="top_margin">
        <div>
          <h4 className="c_blue f_bold t_upper t_underline">Monthly Resource Rate Calculation</h4>
        </div>
        <div>
          <p>Based on the total FTE headcount and shift mix for all roles, the total estimated monthly run rate is as follows:</p>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                <th>#</th>
                <th>Role Name/Description</th>
                <th>FTE Count</th>
                <th>Standard Hourly Rate per FTE</th>
                <th>Overtime Hourly Rate per FTE</th>
                <th>Night Shift Hourly Rate per FTE</th>
                <th>Weekend Hourly Rate per FTE</th>
                <th>Public Holidays Hourly Rate per FTE</th>
                <th>Estimated Monthly Run Rate</th>
              </tr>
            </thead>
            <tbody>
              {this.props.monthlyResults.map((row, rowIndex) => {
                if (!(!showLast && (rowIndex + 1 === this.props.monthlyResults.length))) {
                  return (
                    <tr>
                      <td>{row['#']}</td>
                      <td>{row.RoleNameDescription}</td>
                      <td>{row.FTECount}</td>
                      <td>{UtilsFunction.formatDollarValue(`${row.StandardHourlyRatePerFTE}`, this.state.Currency[this.props.billingCurrency])}</td>
                      <td>{UtilsFunction.formatDollarValue(`${row.OvertimeHourlyRatePerFTE}`, this.state.Currency[this.props.billingCurrency])}</td>
                      <td>{UtilsFunction.formatDollarValue(`${row.NightShiftHourlyRatePerFTE}`, this.state.Currency[this.props.billingCurrency])}</td>
                      <td>{UtilsFunction.formatDollarValue(`${row.WeekendHourlyRatePerFTE}`, this.state.Currency[this.props.billingCurrency])}</td>
                      <td>{UtilsFunction.formatDollarValue(`${row.PublicHolidaysHourlyRatePerFTE || row.PublicHolidayHourlyRatePerFTE}`, this.state.Currency[this.props.billingCurrency])}</td>
                      <td>{UtilsFunction.formatDollarValue(`${row.EstimatedMonthlyRunRate}`, 'M')}</td>
                    </tr>
                  );
                }
                return null;
              })}
            </tbody>
          </table>
        </div>
        <div className="flex flex_space_between">
          <div>
            <div className="flex flex_space_between">
              <div>
                Total FTES:
              </div>
              <div>
                { totalsComputed && totalsComputed.TotalFTES}
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex_space_between">
              <div>
                Total Estimated Monthly Run Rate:
              </div>
              <div>
                {UtilsFunction.formatDollarValue(`${totalsComputed && totalsComputed.TotalEstimatedMonthlyRunRate}`, 'M')}
              </div>
            </div>
          </div>
        </div>
        {totalsComputed && (totalsComputed.TotalBillableResources && totalsComputed.ImpliedBlendedRatePerFTEPerHour) && (
        <div className="flex flex_space_between">
          <div>
            <div className="flex flex_space_between">
              <div>
                Total Billable Hours (K):
              </div>
              <div>
                {totalsComputed && totalsComputed.TotalBillableResources}
              </div>
            </div>
          </div>
          <div>
            <div className="flex flex_space_between">
              <div>
                Implied Blended Rate per Billable Resource per Hour:
              </div>
              <div>
                {totalsComputed && UtilsFunction.formatDollarValue(totalsComputed.ImpliedBlendedRatePerFTEPerHour, this.state.Currency[this.props.currency])}
              </div>
            </div>
          </div>
        </div>
        )}
        {this.props.showDownload && (
        <div className="top_space_added flex flex_end">
          <div className="flex flex_space_between">
            <div>
              <button className="blue_button" type="button" title={constantImports.TOOL_TIP_MSG.downloadBtn} disabled={this.props.monthlyResults.length === 0} onClick={() => this.downloadClicked()}>
                <span>Download Results</span>
              </button>
            </div>
          </div>
        </div>
        )}
      </div>
    );
  }

  render() {
    return <div>{this.mainBody()}</div>;
  }
}

MonthlyResourceRateCalc.propTypes = {
  programName: PropTypes.string,
  desription: PropTypes.string,
  billingCurrency: PropTypes.string,
  calendarYear: PropTypes.string,
  communicationLevel: PropTypes.string,
  technicalLevel: PropTypes.string,
  resiliency: PropTypes.string,
  includeSupernova: PropTypes.string,
  includeWFM: PropTypes.string,
  monthlyResults: PropTypes,
  hourlyConstructed: PropTypes,
  totals: PropTypes.string,
  showDownload: PropTypes.string,
  currency: PropTypes.string,
};

export default MonthlyResourceRateCalc;
