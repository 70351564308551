import React from 'react';
import './App.css';
import Cookies from 'js-cookie';

import {
  Modal, Button, Spinner,
} from 'react-bootstrap';

import { isExpired, decodeToken } from 'react-jwt';

import axios from 'axios';
import * as constantImports from './constants';
import dynamicTabsIndex from './dynamicTabs/dynamicTabsIndex';
import * as UtilsFunction from './utils';
import * as apisConst from './apis.const';

import 'bootstrap/dist/css/bootstrap.min.css';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      grid: [],
      initialSelection: 'none',
      subselections: {},
      loading: false,
      alertClicked: false,

      userName: null,
      preferredEmail: null,
      userRoles: null,
      expirationDate: null,
      isDateExpired: true,
      idToken: null,
      showModal: false,
      showModalSupport: false,
      showModalUpdates: false,
      isPageHide: true,
      didPageLoad: true,
      isLoggedOut: false,

      authorizedRoles: apisConst.AUTH_ROLES,

      currentYearFetched: '',
      rateConfigFetched: '',
      dynamicTabState: Object.keys(dynamicTabsIndex).reduce((o, key) => ({ ...o, [key]: null }), {}),
    };

    this.embedRef = React.createRef();
  }

  componentDidMount() {
    this.getToken();
  }

  handleClose() {
    Cookies.remove('expiration_date');
    Cookies.remove('preferred_email');
    Cookies.remove('user_roles');
    Cookies.remove('user_name');
    Cookies.remove('id_token');
    Cookies.remove('refreshed');

    UtilsFunction.authenticateWithCognito(process.env.REACT_APP_ENVIROMENT);
  }

  handleBusinessAreaChange = (event) => {
    this.setState({ initialSelection: event.target.value }, () => {
      const selectionObject = constantImports.initialSelectionsData[event.target.value];
      if (selectionObject.dynamicTabs && selectionObject.dynamicTabs[0]) {
        let showModalUpdates = false;
        if (this.state.dynamicTabState.RateLookUp === null) {
          showModalUpdates = true;
        } else {
          this.state.dynamicTabState.RateLookUp.selections.forEach((element) => {
            delete element.Language;
          });
          delete this.state.dynamicTabState.RateLookUp.hourlyResults;
        }
        this.setState({
          // grid: selectionObject.dynamicTabs[0], subselections: { desription: '' }, dynamicTabState: UtilsFunction.setAll(this.state.dynamicTabState, null),
          grid: selectionObject.dynamicTabs[0], subselections: { desription: '' }, showModalUpdates,
        });
      } else {
        this.setState({
          // grid: selectionObject.tabs[0].pdfUrl, subselections: { desription: '' }, dynamicTabState: UtilsFunction.setAll(this.state.dynamicTabState, null),
          grid: selectionObject.tabs[0].pdfUrl, subselections: { desription: '' },
        }, () => UtilsFunction.changeEmbed(selectionObject.tabs[0].pdfUrl, this.embedRef, this.state.idToken));
      }
    });
  }

  handleTabChange(tab) {
    const currentTab = this.state.grid;
    if (currentTab === 'MonthlyRunRate') {
      const updateMonthlyRate = this.state.dynamicTabState;
      updateMonthlyRate.MonthlyRunRate = null;
      this.setState({
        dynamicTabsIndex: updateMonthlyRate,
      });
    }
    if (tab.pdfUrl) {
      this.setState({ grid: tab.pdfUrl }, () => UtilsFunction.changeEmbed(tab.pdfUrl, this.embedRef, this.state.idToken));
    } else {
      if (tab === 'MonthlyRunRate' && (!this.state.dynamicTabState.RateLookUp || !this.state.dynamicTabState.RateLookUp.hourlyResults || this.state.dynamicTabState.RateLookUp.hourlyResults.length === 0)) {
        alert('Please submit roles on the Rate Look Up tab');
      }
      this.setState({ grid: tab });
    }
  }

  onRadioChange(selection, subSelectionTitle) {
    this.setState((prevState) => {
      const newStateObject = {
        ...prevState.subselections,
      };
      newStateObject[subSelectionTitle] = selection;
      const newDynamicTabStateObject = prevState.dynamicTabState;
      if (!newDynamicTabStateObject.RateLookUp) {
        newDynamicTabStateObject.RateLookUp = {};
      }
      newDynamicTabStateObject.RateLookUp.hourlyResults = [];
      if (subSelectionTitle === 'communicationLevel' || subSelectionTitle === 'technicalLevel') {
        newDynamicTabStateObject.RateLookUp.selections.forEach((element) => {
          element.Role = undefined;
          element.Level = undefined;
          element.SubLevel = undefined;
          element.Location = undefined;
          element.Language = undefined;
        });
      }
      return ({ subselections: newStateObject, dynamicTabState: newDynamicTabStateObject });
    });
  }

  onFormChange(e, subSelectionTitle) {
    this.setState((prevState) => {
      const newStateObject = {
        ...prevState.subselections,
      };
      newStateObject[subSelectionTitle] = e.target.value;

      if (subSelectionTitle === 'desription') {
        const pattern = /[!@#$%^&*()_+{}:"<>]/;
        let validation = pattern.test(e.target.value);
        if (e.target.value.length > 100) {
          validation = true;
        }

        if (validation) {
          newStateObject[subSelectionTitle] = prevState.subselections.desription;
        }
      }

      const newDynamicTabStateObject = prevState.dynamicTabState;
      newDynamicTabStateObject.RateLookUp.hourlyResults = [];
      return ({ subselections: newStateObject, dynamicTabState: newDynamicTabStateObject });
    });
  }

  async getToken() {
    if (Cookies.get('id_token') !== '' && Cookies.get('id_token') != null) {
      if (Cookies.get('refreshed')) {
        let expirationDate = String(Cookies.get('expiration_date')).split(',');
        expirationDate = new Date(expirationDate * 1000);
        const isMyTokenExpired = isExpired(Cookies.get('id_token'));

        await this.setState({
          userName: Cookies.get('user_name'),
          preferredEmail: Cookies.get('preferred_email'),
          userRoles: String(Cookies.get('user_roles')).split(','),
          expirationDate,
          isDateExpired: isMyTokenExpired,
          idToken: Cookies.get('id_token'),
        });

        if (isMyTokenExpired) {
          this.handleClose();
        } else {
          this.checkAuth(isMyTokenExpired, Cookies.get('id_token'));
        }
      } else {
        this.handleClose();
      }
    } else {
      const results = new RegExp('[\?&#]id_token=([^&#]*)').exec(window.location.href);
      let idToken = null;
      let userName = null;
      let roles = null;
      let preferredEmailCamel = null;
      let expirationDate = null;

      if (results) {
        idToken = results[1];

        const myDecodedToken = decodeToken(idToken);
        const isMyTokenExpired = isExpired(idToken);

        if (idToken && idToken.indexOf('.') > 0) {
          const signature = (idToken.split('.')[2]) ? idToken.split('.')[2] : [];
          if (signature.length <= 0) {
            this.handleClose();
            return;
          }

          userName = myDecodedToken.name;
          roles = myDecodedToken.roles;
          preferredEmailCamel = myDecodedToken.preferred_username;
          expirationDate = myDecodedToken.exp;
        }

        Cookies.set('user_name', userName, { secure: true });
        Cookies.set('user_roles', roles, { secure: true });
        Cookies.set('preferred_email', preferredEmailCamel, { secure: true });
        Cookies.set('expiration_date', expirationDate, { secure: true });
        Cookies.set('id_token', idToken, { secure: true });
        Cookies.set('refreshed', false, { secure: true });
        await this.setState({
          userName,
          preferredEmail: preferredEmailCamel,
          userRoles: String(roles).split(','),
          expirationDate: isMyTokenExpired,
          isDateExpired: isMyTokenExpired,
          idToken,
        });
        this.checkAuth(isMyTokenExpired, idToken);
      } else {
        this.handleClose();
      }
    }
  }

  mainBody = () => {
    const DynamicTabToRender = dynamicTabsIndex[this.state.grid];
    return (
      <article>
        <div>
          {constantImports.initialSelectionsData[this.state.initialSelection].preContentText}
        </div>
        <div className="tab top_space_added">
          {constantImports.initialSelectionsData[this.state.initialSelection].dynamicTabs.map((tab) => <button className={`dynamic_tab ${this.state.grid === tab ? 'dynamic_tab_active' : 'dynamic_tab_inactive'}`} onClick={() => this.handleTabChange(tab)}>{UtilsFunction.changeCamelCaseToSentenceCase(tab)}</button>)}
          {constantImports.initialSelectionsData[this.state.initialSelection].tabs.map((tab) => <button onClick={() => this.handleTabChange(tab)}>{tab.name}</button>)}
        </div>
        <div className="content">
          {(typeof this.state.grid === 'string' || this.state.grid instanceof String) && (this.state.grid.includes('http')
            ? <embed ref={this.embedRef} type="application/pdf" />
            : (
              <DynamicTabToRender
                changeDynamicTabState={(newState, dynamicTabKey, toRunAfter) => {
                  const temporaryState = {};
                  temporaryState.dynamicTabState = this.state.dynamicTabState;
                  temporaryState.dynamicTabState[dynamicTabKey] = Object.assign(this.state.dynamicTabState[dynamicTabKey] || {}, newState);
                  this.setState(temporaryState, () => {
                    if (toRunAfter) {
                      toRunAfter();
                    }
                  });
                }}
                loadingSet={(callbackSetState) => this.setState({ loading: !this.state.loading }, () => callbackSetState())}
                dynamicTabState={this.state.dynamicTabState}
                {...this.state.subselections}
                programName={this.state.initialSelection}
                idToken={this.state.idToken}
                preferredEmail={this.state.preferredEmail}
              />
            )
          )}
        </div>
      </article>
    );
  }

  async checkAuth(exp, idToken) {
    if (this.state.userRoles.length > 0) {
      for (let i = 0; i < this.state.userRoles.length; i += 1) {
        let isFound = this.state.authorizedRoles.some((element) => element === this.state.userRoles[i]);
        if (this.state.authorizedRoles[0] === 'FULL_ACCESS') {
          isFound = true;
        }
        if (isFound && !exp) {
          if (window.location.hash.length > 0) {
            Cookies.set('refreshed', true, { secure: true });
            window.location = window.location.origin;
            return;
          }

          const requestOptions = {
            method: 'GET',
            headers: constantImports.amazonHeader(idToken),
          };
          // eslint-disable-next-line no-await-in-loop
          await axios.get(`${constantImports.AMAZON_API_BASE}/calculation-data/rate-config.txt`, requestOptions)
            .then((response) => {
              this.setState({
                currentYearFetched: parseInt(response.data.Current_year),
                rateConfigFetched: response.data,
              });
            });

          this.setState({ isPageHide: false });

          const expirationDate = this.state.expirationDate;
          const today = new Date();
          const milliseconds = Math.abs(expirationDate - today);

          setTimeout(() => {
            this.setState({
              showModal: true,
              didPageLoad: false,
              isDateExpired: true,
              isPageHide: true,
            });
          }, milliseconds);

          if (Cookies.get('refreshed')) {
            Cookies.remove('id_token');
            Cookies.remove('expiration_date');
            Cookies.remove('user_roles');
            Cookies.remove('user_name');
            Cookies.remove('user_email');
            Cookies.remove('refreshed');
            Cookies.remove('preferred_email');
          }

          return;
        }
        if (i === (this.state.userRoles.length - 1)) {
          this.setState({ didPageLoad: false });
        }
      }
    } else {
      this.setState({ didPageLoad: false });
    }
  }

  headerComponent() {
    return (
      <div className="flex flex_space_between">
        <div>
          <div className="flex flex_start">
            <div>
              <img src="accenture.png" width="150" height="49" alt="" />
              <img src="meta.svg" height="70" alt="" />
            </div>
            <div className="c_blue t_upper header">
              Rate Calculator
            </div>
          </div>
        </div>
        <div className="flex flex_space_around">
          <div title={constantImports.TOOL_TIP_MSG.userGuide}>
            <button className="blue_button white_button noborder_white_button" type="button" onClick={() => this.setState({ showModalSupport: true })}>
              <span>Support</span>
            </button>
            <button className="blue_button white_button noborder_white_button" type="button" onClick={() => this.setState({ alertClicked: false })}>
              <span>Terms of use</span>
            </button>
            <button className="blue_button" type="button" onClick={() => window.open('/Rate%20Calculator%20User%20Guide%20v2.0.pdf', '_blank')}>
              <span>User Guide</span>
            </button>
            <button className="blue_button white_button" type="button" onClick={() => this.setState({ isLoggedOut: true, didPageLoad: false, isPageHide: true })}>
              <span>Log out</span>
            </button>
          </div>
        </div>
      </div>
    );
  }

  initialSelections() {
    return (
      <div className="flex">
        <div className={`half_expand${this.state.grid == 'MonthlyRunRate' ? ' not_allowed' : ''}`} title={this.state.grid == 'MonthlyRunRate' && constantImports.TOOL_TIP_MSG.topRadioDisabled}>
          <div className="flex flex_column">
            <div>
              <h4 className="c_blue f_bold t_upper m_zero t_underline">
                Program Details
              </h4>
            </div>
            <div title={constantImports.TOOL_TIP_MSG.businessArea}>
              <form className="flex bottom_top_padding_added">
                <label className="custom_width_label_large">
                  Business Area:
                </label>
                <select value={this.state.initialSelection} onChange={this.handleBusinessAreaChange}>
                  <option value="none">Select one</option>
                  {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_CONTENT_REVIEW', 'Content Review')}
                  {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_PSO', 'PSO')}
                  {/* {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_MARKETING', 'Marketing')} */}
                  {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_CONSULTING', 'Consulting')}
                  {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_INFRASTRUCTURE', 'Infrastructure')}
                  {/* {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_HR_OPERATIONS', 'HR Operations')} */}
                  {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_LEGAL', 'Legal')}
                  {UtilsFunction.optionGenerateBusinessArea(this.state.userRoles, 'FB_RATE_CALCULATOR_FAO', 'Finance & Accounting Outsourcing')}
                </select>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  subSelections() {
    return (
      <div className="flex">
        <div className={`half_expand${this.state.grid == 'MonthlyRunRate' ? ' not_allowed' : ''}`} title={this.state.grid == 'MonthlyRunRate' && constantImports.TOOL_TIP_MSG.topRadioDisabled}>
          <div className="flex flex_column">
            <div>
              <h4 className="c_blue f_bold t_upper m_zero t_underline">
                Program Details
              </h4>
            </div>
            {constantImports.initialSelectionsData[this.state.initialSelection].programDetails.map((programDetail, programDetailIndex) => (
              <div title={constantImports.TOOL_TIP_MSG[constantImports.subSelectionsData[programDetail].title]}>
                <div className="flex bottom_top_padding_added">
                  <div className="custom_width_label_large">
                    <p>
                      {constantImports.subSelectionsData[programDetail].label}
                    </p>
                  </div>
                  <div>
                    {this.renderAccordingToType(constantImports.subSelectionsData[programDetail], programDetail, [Number(this.state.currentYearFetched), Number(this.state.currentYearFetched) + 1])}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={`half_expand${this.state.grid == 'MonthlyRunRate' ? ' not_allowed' : ''}`} title={this.state.grid == 'MonthlyRunRate' && constantImports.TOOL_TIP_MSG.topRadioDisabled}>
          <div className="flex flex_column">
            <div>
              <h4 className="c_blue f_bold t_upper m_zero t_underline">Program Pricing Factors</h4>
            </div>
            {constantImports.initialSelectionsData[this.state.initialSelection].programPricingFactors.map((programPricingFactor, programPricingFactorIndex) => (
              <div title={constantImports.TOOL_TIP_MSG[constantImports.subSelectionsData[programPricingFactor].title]}>
                <div className="flex bottom_top_padding_added">
                  <div className="custom_width_label_large">
                    <p>
                      {constantImports.subSelectionsData[programPricingFactor].label}
                    </p>
                  </div>
                  <div>
                    <form>
                      {this.renderAccordingToType(constantImports.subSelectionsData[programPricingFactor], programPricingFactor)}
                    </form>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }

  renderAccordingToType(subSelectionObject, subSelectionTitle, optionalLabels) {
    switch (subSelectionObject.type) {
      case 'textBox':
        return <input type="text" maxLength={100} onChange={(e) => this.onFormChange(e, subSelectionTitle)} value={this.state.subselections[subSelectionTitle] || ''} />;
      case 'radioButtons':
        return subSelectionObject.selections.map((selection, selectionIndex) => (
          <label className="custom_width_label_small" title={(optionalLabels && optionalLabels.length) > 0 ? optionalLabels[selectionIndex] : null}>
            <input
              className="custom_label"
              type="radio"
              value={selection.customValue || selection}
              checked={this.state.subselections[subSelectionTitle] === selection}
              onChange={(e) => this.onRadioChange(selection, subSelectionTitle)}
            />
            <span className="custom_label custom_label_text">
              {selection.customLabel || selection}
            </span>
          </label>
        ));
      default:
    }
  }

  render() {
    return (
      <>
        <div className={`custom_alert custom_alert_loading${!this.state.loading ? ' hide' : ''}`}>
          <div className="loader" />
          <div className="top_margin message vertical_align_center paragraph_center">
            {constantImports.LOADING_MESSAGE}
          </div>
        </div>
        {!this.state.isPageHide ? (
          <div>
            <div className={`custom_alert${this.state.alertClicked ? ' hide' : ''}`}>
              <div>
                <p className="message">{this.state.rateConfigFetched.Disclaimer}</p>
                <p className="message version_message">
                  Current Version
                  {' '}
                  {this.state.rateConfigFetched.Version}
                </p>
                <p className="message version_message description">{this.state.rateConfigFetched.Version_Notes}</p>
                <p className="message version_message description" style={{ marginBottom: '20px' }}>
                  Last Time Updated:
                  {' '}
                  {this.state.rateConfigFetched.Last_Update}
                </p>
              </div>
              <div className="flex">
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <input type="checkbox" checked={this.state.alertClicked} onChange={() => this.setState({ alertClicked: !this.state.alertClicked })} />
                </div>
                <div className="vertical_align_center">
                  <p className="vertical_align_center">Click here to accept these terms of use.</p>
                </div>
              </div>
            </div>
            <div className={((!this.state.alertClicked || this.state.loading) && 'not_alert')}>
              {
                this.state.initialSelection === 'none'
                  ? [
                    <header>
                      {this.headerComponent()}
                    </header>,
                    <section>
                      {this.initialSelections()}
                    </section>,
                  ] : [
                    <header>
                      {this.headerComponent()}
                    </header>,
                    <section>
                      {this.initialSelections()}
                    </section>,
                    <section>
                      {this.subSelections()}
                    </section>,
                    <section>
                      {this.mainBody()}
                    </section>,
                  ]
              }
            </div>
          </div>
        ) : (
          <div className="page-load flex flex-center">
            {this.state.didPageLoad ? (
              <div className="flex flex-center flex-d-column">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading</span>
                </Spinner>
                <p className="fs-14">{constantImports.loadingText}</p>
              </div>
            ) : (
              <div>
                {this.state.isLoggedOut ? (
                  <div className="flex flex-center flex-d-column">
                    <p className="fs-14">{constantImports.loggedOutText}</p>
                    <button className="blue_button" type="button" onClick={() => this.handleClose()}>Log In</button>
                  </div>
                ) : (
                  <div>
                    {!this.state.isDateExpired ? <p className="fs-14">{constantImports.errorText}</p> : ''}
                  </div>
                )}
              </div>
            ) }
          </div>
        )}

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Header>
            <Modal.Title>Warning</Modal.Title>
          </Modal.Header>
          <Modal.Body>The session time has expired. Please log in again.</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={this.handleClose}>
              Log In
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showModalSupport} onHide={() => this.setState({ showModalSupport: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Support</Modal.Title>
          </Modal.Header>
          <Modal.Body>{constantImports.supportText}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.setState({ showModalSupport: false })}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.showModalUpdates} onHide={() => this.setState({ showModalUpdates: false })}>
          <Modal.Header closeButton>
            <Modal.Title>Updates</Modal.Title>
          </Modal.Header>
          <Modal.Body>{constantImports.updateText}</Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.setState({ showModalUpdates: false })}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default App;
