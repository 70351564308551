import {
  AREA_NAME,
} from './consTypes';

export const DEFAULT_HOUR_BILL = {
  default: 'Billable Units',
  name1: 'Billable Units Per Year',
};

export const DEFAULT_HOUR_RATE_FTE = {
  default: 'Standard Hourly Rate per FTE',
  name1: 'Standard Rate per FTE per Billable Unit',
};

export const RATE_UP_HOUR_BILL_CTRL = {
  [AREA_NAME.em]: DEFAULT_HOUR_BILL.default,
  [AREA_NAME.ContReview]: DEFAULT_HOUR_BILL.default,
  [AREA_NAME.Pso]: DEFAULT_HOUR_BILL.default,
  [AREA_NAME.FAO]: DEFAULT_HOUR_BILL.name1,
  [AREA_NAME.Legal]: DEFAULT_HOUR_BILL.name1,
  [AREA_NAME.HrO]: DEFAULT_HOUR_BILL.name1,
  [AREA_NAME.Infra]: DEFAULT_HOUR_BILL.name1,
  [AREA_NAME.Consult]: DEFAULT_HOUR_BILL.name1,
  [AREA_NAME.Market]: DEFAULT_HOUR_BILL.name1,
};

export const RATE_UP_HOUR_RATE_FTE_CTRL = {
  [AREA_NAME.em]: DEFAULT_HOUR_RATE_FTE.default,
  [AREA_NAME.ContReview]: DEFAULT_HOUR_RATE_FTE.default,
  [AREA_NAME.Pso]: DEFAULT_HOUR_RATE_FTE.default,
  [AREA_NAME.FAO]: DEFAULT_HOUR_RATE_FTE.name1,
  [AREA_NAME.Legal]: DEFAULT_HOUR_RATE_FTE.default,
  [AREA_NAME.HrO]: DEFAULT_HOUR_RATE_FTE.name1,
  [AREA_NAME.Infra]: DEFAULT_HOUR_RATE_FTE.default,
  [AREA_NAME.Consult]: DEFAULT_HOUR_RATE_FTE.default,
  [AREA_NAME.Market]: DEFAULT_HOUR_RATE_FTE.name1,
};
