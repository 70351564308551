import React from 'react';
import PropTypes, { element } from 'prop-types';
import Cookies from 'js-cookie';
import '../../App.css';
import { readRemoteFile } from 'react-papaparse';
import * as constantImports from '../../constants';
import * as UtilsFunction from '../../utils';

const defaultSelections = [
  { indexSelection: 1, CommunicationTechnicalComplexityUplift: 'Yes' },
  { indexSelection: 2, CommunicationTechnicalComplexityUplift: 'Yes' },
  { indexSelection: 3, CommunicationTechnicalComplexityUplift: 'Yes' },
];

function isColAvailable(programName, colName) {
  return constantImports.initialSelectionsData[programName].resourceRequirementCols.includes(colName);
}

class RateLookUpResourceRequirements extends React.Component {
  componentDidMount() {
    (!this.props.dynamicTabState.RateLookUp || !this.props.dynamicTabState.RateLookUp.selections) && this.props.changeDynamicTabState('selections', defaultSelections, () => this.setUpSelectionObjects());
    (!this.props.dynamicTabState.RateLookUp || !this.props.dynamicTabState.RateLookUp.businessAreaRoleObject) && this.props.changeDynamicTabState('businessAreaRoleObject', {}, () => this.setUpBusinessAreaRoleObject());
    (!this.props.dynamicTabState.RateLookUp || !this.props.dynamicTabState.RateLookUp.locationPerLevelSubLevelBusinessArea) && this.props.changeDynamicTabState('locationPerLevelSubLevelBusinessArea', [], () => this.setUpLocationPerLevelSubLevelBusinessArea());
    (!this.props.dynamicTabState.RateLookUp || !this.props.dynamicTabState.RateLookUp.complexityMultipliersByLocation) && this.props.changeDynamicTabState('complexityMultipliersByLocation', [], () => this.setComplexityMultipliersByLocation());
    (!this.props.dynamicTabState.RateLookUp || !this.props.dynamicTabState.RateLookUp.locationPerLanguages) && this.props.changeDynamicTabState('locationPerLanguages', [], () => this.setUpLocationPerLanguages());
  }

  componentDidUpdate(prevProps) {
    let propsHaveChange = false;
    const newPropValue = {};
    constantImports.ALL_ROW_REQUIRED.forEach((requiredAllRow) => {
      newPropValue[requiredAllRow] = this.props[UtilsFunction.lowerFirstLetter(requiredAllRow)];
      if (this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.selections && this.props.dynamicTabState.RateLookUp.selections[0] && (this.props.dynamicTabState.RateLookUp.selections[0][requiredAllRow] !== this.props[UtilsFunction.lowerFirstLetter(requiredAllRow)])) {
        propsHaveChange = true;
      }
    });
    if (!this.props.dynamicTabState.RateLookUp || this.props.dynamicTabState.RateLookUp.selections.length === 0) {
      this.props.changeDynamicTabState('selections', defaultSelections.map((el) => ({ ...el, ...newPropValue })), () => {
        this.props.changeDynamicTabState('businessAreaRoleObject', {}, () => this.setUpBusinessAreaRoleObject());
        this.props.changeDynamicTabState('locationPerLevelSubLevelBusinessArea', [], () => this.setUpLocationPerLevelSubLevelBusinessArea());
        this.props.changeDynamicTabState('complexityMultipliersByLocation', [], () => this.setComplexityMultipliersByLocation());
        this.props.changeDynamicTabState('locationPerLanguages', [], () => this.setUpLocationPerLanguages());
      });
    }
    if (propsHaveChange) {
      this.props.changeDynamicTabState('selections', this.props.dynamicTabState.RateLookUp.selections.map((el) => ({ ...el, ...newPropValue })));
    }
  }

  onDropdownChange(valuePassed, selectionIndex, property, toRunAfter) {
    const newPropValue = {};
    newPropValue[property] = valuePassed;
    this.props.changeDynamicTabState('selections', this.props.dynamicTabState.RateLookUp.selections.map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el)), () => {
      this.props.changeDynamicTabState('hourlyResults', []);
      toRunAfter && toRunAfter();
    });
  }

  onRadioChange(property, selectionIndex) {
    const newPropValue = {};
    const originalObject = this.props.dynamicTabState.RateLookUp.selections.filter((el) => (el.indexSelection === selectionIndex))[0];
    newPropValue[property] = (originalObject[property] === 'No' ? 'Yes' : 'No');
    this.props.changeDynamicTabState('selections', this.props.dynamicTabState.RateLookUp.selections.map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el)));
  }

  setUpLocationPerLevelSubLevelBusinessArea() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Base%20Rates%20by%20Level%20and%20Location.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        this.props.changeDynamicTabState('locationPerLevelSubLevelBusinessArea', results.data);
      },
    });
  }

  getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(level, sublevel, businessArea, communicationWorkComplexity, domainTechExpertise) {
    return UtilsFunction.getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(level, sublevel, businessArea, communicationWorkComplexity, domainTechExpertise, this.props.dynamicTabState.RateLookUp.locationPerLevelSubLevelBusinessArea, this.props.dynamicTabState.RateLookUp.complexityMultipliersByLocation);
  }

  getLanguagePerLocation(location) {
    const resultArray = [];
    let locationIndex = -1;
    for (let i = 1; i < this.props.dynamicTabState.RateLookUp.locationPerLanguages[0].length; i += 1) {
      if (this.props.dynamicTabState.RateLookUp.locationPerLanguages[0][i] === location) {
        locationIndex = i;
      }
    }

    if (locationIndex > 0) {
      for (let i = 1; i < this.props.dynamicTabState.RateLookUp.locationPerLanguages.length; i += 1) {
        if ((this.props.dynamicTabState.RateLookUp.locationPerLanguages[i][locationIndex] !== '')) {
          resultArray.push(this.props.dynamicTabState.RateLookUp.locationPerLanguages[i][0]);
        }
      }
    }

    return resultArray;
  }

  setUpBusinessAreaRoleObject() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Role%20by%20Business%20Area.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        const businessAreaRoleObject = {};
        for (let i = 0; i < results.data.length; i += 1) {
          if (businessAreaRoleObject[results.data[i][0]]) {
            businessAreaRoleObject[results.data[i][0]].push({
              roleName: results.data[i][1],
              level: results.data[i][2],
              subLevel: results.data[i][3],
            });
          } else {
            businessAreaRoleObject[results.data[i][0]] = [];
            businessAreaRoleObject[results.data[i][0]].push({
              roleName: results.data[i][1],
              level: results.data[i][2],
              subLevel: results.data[i][3],
            });
          }
        }
        this.props.changeDynamicTabState('businessAreaRoleObject', businessAreaRoleObject);
      },
    });
  }

  setComplexityMultipliersByLocation() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Complexity%20Multipliers%20by%20Location.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        this.props.changeDynamicTabState('complexityMultipliersByLocation', results.data.filter((itemFiltered) => itemFiltered.length > 1));
      },
    });
  }

  setUpLocationPerLanguages() {
    readRemoteFile(`${constantImports.AMAZON_API_BASE}/calculation-data/Language%20Tier%20by%20Location.csv`, {
      downloadRequestHeaders: constantImports.amazonHeader(this.props.idToken),
      complete: (results) => {
        this.props.changeDynamicTabState('locationPerLanguages', results.data);
      },
    });
  }

  setUpSelectionObjects() {
    const newPropValue = {};
    let propsHaveChange = false;
    constantImports.ALL_ROW_REQUIRED.forEach((requiredAllRow) => {
      newPropValue[requiredAllRow] = this.props[UtilsFunction.lowerFirstLetter(requiredAllRow)];
      if (this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.selections && this.props.dynamicTabState.RateLookUp.selections[0] && (this.props.dynamicTabState.RateLookUp.selections[0][requiredAllRow] !== this.props[UtilsFunction.lowerFirstLetter(requiredAllRow)])) {
        propsHaveChange = true;
      }
    });
    if (propsHaveChange) {
      this.props.changeDynamicTabState('selections', this.props.dynamicTabState.RateLookUp.selections.map((el) => ({ ...el, ...newPropValue })));
    }
  }

  getSpecificSelection(selectionObject) {
    return this.props.dynamicTabState.RateLookUp.businessAreaRoleObject[selectionObject.ProgramName].filter((objIter) => objIter.roleName === selectionObject.Role)[0];
  }

  mainBody = () => (
    <div className="top_margin">
      <div>
        <h4 className="c_blue f_bold t_upper t_underline">Resource Requirements</h4>
      </div>
      <div>
        <p>Please configure the rates as defined by choice of ROLE, LOCATION and LANGUAGE. For a Custom role, also select the LEVEL and SUB-LEVEL.</p>
      </div>
      <div>
        <table>
          <thead>
            <tr>
              <th>#</th>
              {isColAvailable(this.props.programName, 'roleCol') && <th title={constantImports.TOOL_TIP_MSG.roleCol}>Role</th>}
              {isColAvailable(this.props.programName, 'levelCol') && <th title={constantImports.TOOL_TIP_MSG.levelCol}>Level</th>}
              {isColAvailable(this.props.programName, 'subLevelCol') && <th title={constantImports.TOOL_TIP_MSG.subLevelCol}>Sub-Level</th>}
              {isColAvailable(this.props.programName, 'includeComplexity') && <th title={constantImports.TOOL_TIP_MSG.includeComplexity}>Complexity Cost Included</th>}
              {isColAvailable(this.props.programName, 'locationCol') && <th title={constantImports.TOOL_TIP_MSG.locationCol}>Location</th>}
              {isColAvailable(this.props.programName, 'langCol') && <th title={constantImports.TOOL_TIP_MSG.langCol}>Language</th>}
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.selections && this.props.dynamicTabState.RateLookUp.businessAreaRoleObject && this.props.dynamicTabState.RateLookUp.selections.sort(UtilsFunction.orderSelections).map((selectionObject, selectionIndex) => (
              <tr key={UtilsFunction.getRandomNumber()}>
                <td>{selectionIndex + 1}</td>
                {isColAvailable(this.props.programName, 'roleCol') && (
                  <td title={constantImports.TOOL_TIP_MSG.roleCol}>
                    <form>
                      <select
                        key={UtilsFunction.getRandomNumber()}
                        value={selectionObject.Role}
                        onChange={
                          (e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Role', () => {
                            this.removeLangLoc(selectionObject.indexSelection);
                            this.changeLevelSublevel(e.target.value, selectionObject.indexSelection);
                          })
                        }
                      >
                        <option value="selectOne">select one</option>
                        {this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.businessAreaRoleObject && this.props.dynamicTabState.RateLookUp.businessAreaRoleObject[selectionObject.ProgramName] && this.props.dynamicTabState.RateLookUp.businessAreaRoleObject[selectionObject.ProgramName].map((roleOption, roleOptionIndex) => {
                          if (this.getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(roleOption.level, roleOption.subLevel, selectionObject.ProgramName, this.props.communicationLevel, this.props.technicalLevel).length > 0) {
                            return (
                              <option value={roleOption.roleName}>
                                {roleOption.roleName}
                              </option>
                            );
                          }
                          return;
                        })}
                      </select>
                    </form>
                  </td>
                )}
                {isColAvailable(this.props.programName, 'levelCol') && <td title={constantImports.TOOL_TIP_MSG.levelCol}>{this.checkSelectionObject(selectionObject) && this.getSpecificSelection(selectionObject).level}</td>}
                {isColAvailable(this.props.programName, 'subLevelCol') && <td title={constantImports.TOOL_TIP_MSG.subLevelCol}>{this.checkSelectionObject(selectionObject) && this.getSpecificSelection(selectionObject).subLevel}</td>}
                {isColAvailable(this.props.programName, 'includeComplexity') && (
                  <td title={constantImports.TOOL_TIP_MSG.includeComplexity}>
                    <input type="checkbox" checked={selectionObject.CommunicationTechnicalComplexityUplift === 'Yes'} onChange={() => this.onRadioChange('CommunicationTechnicalComplexityUplift', selectionObject.indexSelection)} />
                  </td>
                )}
                {isColAvailable(this.props.programName, 'locationCol') && (
                  <td title={constantImports.TOOL_TIP_MSG.locationCol}>
                    <form>
                      <select
                        key={UtilsFunction.getRandomNumber()}
                        value={selectionObject.Location}
                        onChange={
                          (e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Location', () => {
                            this.removeLang(selectionObject.indexSelection);
                          })
                        }
                      >
                        {/* <select key={UtilsFunction.getRandomNumber()} value={selectionObject.Location} onChange={(e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Location')}> */}
                        <option value="selectOne">select one</option>
                        {this.checkSelectionObject(selectionObject) && this.getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(this.checkSelectionObject(selectionObject) && this.getSpecificSelection(selectionObject).level, this.checkSelectionObject(selectionObject) && this.getSpecificSelection(selectionObject).subLevel, selectionObject.ProgramName, this.props.communicationLevel, this.props.technicalLevel).map((locationOption) => <option value={locationOption}>{locationOption}</option>)}
                      </select>
                    </form>
                  </td>
                )}
                {isColAvailable(this.props.programName, 'langCol') && (
                  <td title={constantImports.TOOL_TIP_MSG.langCol}>
                    <form>
                      <select key={UtilsFunction.getRandomNumber()} value={selectionObject.Language} onChange={(e) => this.onDropdownChange(e.target.value, selectionObject.indexSelection, 'Language')}>
                        <option value="selectOne">select one</option>
                        {selectionObject.Location && this.getLanguagePerLocation(selectionObject.Location).map((languageOption, languageOptionIndex) => (languageOption !== '') && (
                          <option value={languageOption}>
                            {languageOption}
                          </option>
                        ))}
                      </select>
                    </form>
                  </td>
                )}
                <td><a href="#" onClick={() => this.deleteObject(selectionObject.indexSelection)}>Delete</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="top_space_added flex flex_end">
        <div className="flex flex_space_between">
          <div>
            <button className="blue_button blue_button_inverse" type="button" onClick={() => this.addObject()}>
              <span>Add Row</span>
            </button>
          </div>
          <div>
            <button className="blue_button clear_all_button" type="button" title={constantImports.TOOL_TIP_MSG.clearAll} onClick={() => this.clearAllPressed()}>
              <span>Clear All</span>
            </button>
          </div>
          <div>
            <button className="blue_button" type="button" title={constantImports.TOOL_TIP_MSG.submit} disabled={this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.selections && this.props.dynamicTabState.RateLookUp.selections.length === 0} onClick={() => this.submitPressed()}>
              <span>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )

  removeLangLoc(indexPassed) {
    const selectionObjectToChange = this.props.dynamicTabState.RateLookUp.selections.filter((selection) => selection.indexSelection === indexPassed)[0];
    delete selectionObjectToChange.Language;
    delete selectionObjectToChange.Location;
    const selectionObjectToChangeExcluded = this.props.dynamicTabState.RateLookUp.selections.filter((selection) => selection.indexSelection !== indexPassed);
    this.props.changeDynamicTabState('selections', [selectionObjectToChange, ...selectionObjectToChangeExcluded]);
  }

  removeLang(indexPassed) {
    const selectionObjectToChange = this.props.dynamicTabState.RateLookUp.selections.filter((selection) => selection.indexSelection === indexPassed)[0];
    delete selectionObjectToChange.Language;
    const selectionObjectToChangeExcluded = this.props.dynamicTabState.RateLookUp.selections.filter((selection) => selection.indexSelection !== indexPassed);
    this.props.changeDynamicTabState('selections', [selectionObjectToChange, ...selectionObjectToChangeExcluded]);
  }

  addObject() {
    this.props.changeDynamicTabState('selections', [...((this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.selections) || []), {
      indexSelection: (((this.props.dynamicTabState.RateLookUp && this.props.dynamicTabState.RateLookUp.selections) || []).length !== 0 ? Math.max(...(this.props.dynamicTabState.RateLookUp.selections || []).map((o) => o.indexSelection)) : 0) + 1, CommunicationTechnicalComplexityUplift: 'Yes', ProgramName: this.props.programName, billingCurrency: this.props.billingCurrency, calendarYear: this.props.calendarYear, CommunicationLevel: this.props.communicationLevel, Desription: this.props.desription, TechnicalLevel: this.props.technicalLevel, Resiliency: this.props.resiliency, IncludeSupernova: this.props.includeSupernova, IncludeWFM: this.props.includeWFM,
    }], () => this.props.changeDynamicTabState('hourlyResults', []));
  }

  deleteObject(selectionIndex) {
    this.props.changeDynamicTabState('selections', this.props.dynamicTabState.RateLookUp.selections.filter((selectionObject) => selectionObject.indexSelection !== selectionIndex), () => this.props.changeDynamicTabState('hourlyResults', []));
  }

  submitPressed() {
    let allFieldsProvided = true;
    let rowHasElement = true;
    let rowHasAllElements = true;
    let readyToSubmit = true;
    let allBlank = true;

    this.props.dynamicTabState.RateLookUp.selections.forEach((selection, indexOfSelection) => {
      constantImports.PER_ROW_REQUIRED.forEach((requiredPerRow) => {
        if ((!Object.keys(selection).includes(requiredPerRow) || (selection[requiredPerRow] === undefined)) && isColAvailable(this.props.programName, constantImports.CONVERSION_COL_TO_REQUIRED[requiredPerRow])) {
          allFieldsProvided = false;
        }
      });

      if (selection.Language || selection.Location || selection.Role) {
        rowHasElement = true;
      } else {
        rowHasElement = false;
      }
      if (selection.Language && selection.Location && selection.Role) {
        rowHasAllElements = true;
      } else {
        rowHasAllElements = false;
      }

      //
      if (selection.Language && selection.Language === 'selectOne') {
        allFieldsProvided = false;
      }
      if (selection.Location && selection.Location === 'selectOne') {
        allFieldsProvided = false;
      }
      if (selection.Role && selection.Role === 'selectOne') {
        allFieldsProvided = false;
      }
      //

      constantImports.ALL_ROW_REQUIRED.forEach((requiredAllRow) => {
        const withLowerCase = requiredAllRow.charAt(0).toLowerCase() + requiredAllRow.slice(1);
        if ((!Object.keys(selection).includes(requiredAllRow) || (selection[requiredAllRow] === undefined)) && (constantImports.initialSelectionsData[this.props.programName].programPricingFactors.includes(withLowerCase) || constantImports.initialSelectionsData[this.props.programName].programDetails.includes(withLowerCase))) {
          allFieldsProvided = false;
        }
      });

      if (!allFieldsProvided) {
        if (!rowHasElement) {
          this.deleteObject(selection.indexSelection);
        } else {
          readyToSubmit = false;
          allBlank = false;
        }
      } else {
        allBlank = false;
      }
    });

    if (readyToSubmit && !allBlank) {
      const requestOptions = {
        method: 'POST',
        headers: constantImports.amazonHeader(this.props.idToken),
        body: JSON.stringify({
          FormInput: this.props.dynamicTabState.RateLookUp.selections.map((selectionObject) => {
            const { indexSelection, ...excludedObject } = selectionObject;
            return excludedObject;
          }),
          UserData: {
            UserName: this.props.preferredEmail,
          },
        }),
      };
      this.props.loadingSet(() => fetch(`${constantImports.AMAZON_API_BASE}/rate-lookup`, requestOptions).then((response) => {
        if (response.status !== 200) {
          this.props.loadingSet(() => null);
          alert('No information found with those values, or an error occurred.');
        } else {
          response.json().then((data) => {
            this.props.changeDynamicTabState('hourlyResults', data.map((dataItem) => ({ ...dataItem, Level: this.props.dynamicTabState.RateLookUp.selections.filter((selObj) => selObj.role === dataItem.role)[0].Level, SubLevel: this.props.dynamicTabState.RateLookUp.selections.filter((selObj) => selObj.role === dataItem.role)[0].SubLevel })));
            this.props.loadingSet(() => null);
          });
        }
      }));
    } else {
      alert('All required fields were not provided');
    }
  }

  changeLevelSublevel(role, selectionIndex) {
    const newPropValue = {};
    let newLevel;
    let newSubLevel;
    const selectionObject = this.props.dynamicTabState.RateLookUp.selections.filter((el) => (el.indexSelection === selectionIndex))[0];
    this.props.dynamicTabState.RateLookUp.businessAreaRoleObject[selectionObject.ProgramName].forEach((roleObject) => {
      if (roleObject.roleName === role) {
        newLevel = roleObject.level;
        newSubLevel = roleObject.subLevel;
      }
    });
    newPropValue.Level = newLevel;
    newPropValue.SubLevel = newSubLevel;
    this.props.changeDynamicTabState('selections', this.props.dynamicTabState.RateLookUp.selections.map((el) => (el.indexSelection === selectionIndex ? { ...el, ...newPropValue } : el)));
  }

  clearAllPressed() {
    this.props.changeDynamicTabState('selections', defaultSelections, () => {
      this.setUpSelectionObjects();
      this.props.changeDynamicTabState('hourlyResults', []);
    });
  }

  checkSelectionObject(selectionObject) {
    return this.props.dynamicTabState.RateLookUp.businessAreaRoleObject[selectionObject.ProgramName] && this.getSpecificSelection(selectionObject);
  }

  render() {
    return <div>{this.mainBody()}</div>;
  }
}

RateLookUpResourceRequirements.propTypes = {
  communicationLevel: PropTypes.string,
  technicalLevel: PropTypes.string,
  programName: PropTypes.string,
  loadingSet: PropTypes.func,
  dynamicTabState: PropTypes.shape,
  changeDynamicTabState: PropTypes.func,
  preferredEmail: PropTypes.string,
  idToken: PropTypes.string,
  billingCurrency: PropTypes.string,
  calendarYear: PropTypes.objectOf(PropTypes.string),
  desription: PropTypes.string,
  resiliency: PropTypes.string,
  includeSupernova: PropTypes.string,
  includeWFM: PropTypes.string,
};

export default RateLookUpResourceRequirements;
