import React from 'react';
import PropTypes from 'prop-types';
import '../App.css';
import HourlyResourceRateCalc from './components/HourlyResourceRateCalc';
import MonthlyResourceRateCalc from './components/MonthlyResourceRateCalc';
import BlendedResourceRequirements from './components/BlendedResourceRequirements';
import BlendedResourceRateDownload from './components/BlendedResourceRateDownload';

class BlendedRateCalculation extends React.Component {
  constructor(props) {
    super(props);
  }

  mainBody = (props) => (
    <article>
      <BlendedResourceRequirements {...props} changeBlendedResults={(selections, selectionsRolesListed) => this.changeBlendedResults(selections, selectionsRolesListed)} changeHourlyMonthlyResults={(hourlyPassed) => this.changeHourlyMonthlyResults(hourlyPassed)} resetHourlyMonthlyResults={this.resetHourlyMonthlyResults} />
      <HourlyResourceRateCalc {...props} showDownload={false} grid="BlendedRate" hourlyResults={this.props.dynamicTabState.BlendedRateCalculation && this.props.dynamicTabState.BlendedRateCalculation.hourlyResults ? this.props.dynamicTabState.BlendedRateCalculation.hourlyResults : []} />
      <MonthlyResourceRateCalc currency={this.props.billingCurrency} {...props} showDownload={false} monthlyResults={this.props.dynamicTabState.BlendedRateCalculation && this.props.dynamicTabState.BlendedRateCalculation.monthlyResults ? this.props.dynamicTabState.BlendedRateCalculation.monthlyResults : []} totals={this.props.dynamicTabState.BlendedRateCalculation && this.props.dynamicTabState.BlendedRateCalculation.totals ? this.props.dynamicTabState.BlendedRateCalculation.totals : null} />
      <BlendedResourceRateDownload {...props} blendedResults={this.props.dynamicTabState.BlendedRateCalculation ? this.props.dynamicTabState.BlendedRateCalculation.blendedResults : {}} hourlyResults={this.props.dynamicTabState.BlendedRateCalculation && this.props.dynamicTabState.BlendedRateCalculation.hourlyResults ? this.props.dynamicTabState.BlendedRateCalculation.hourlyResults : []} monthlyResults={this.props.dynamicTabState.BlendedRateCalculation && this.props.dynamicTabState.BlendedRateCalculation.monthlyResults ? this.props.dynamicTabState.BlendedRateCalculation.monthlyResults : []} totals={this.props.dynamicTabState.BlendedRateCalculation && this.props.dynamicTabState.BlendedRateCalculation.totals ? this.props.dynamicTabState.BlendedRateCalculation.totals : null} />
    </article>
  )

  resetHourlyMonthlyResults = () => {
    const deletedState = { ...this.props.dynamicTabState.BlendedRateCalculation };
    if (deletedState.hourlyResults || deletedState.monthlyResults || deletedState.totals) {
      deletedState.hourlyResults = undefined;
      deletedState.monthlyResults = undefined;
      deletedState.totals = undefined;
      this.props.changeDynamicTabState(deletedState, 'BlendedRateCalculation');
    }
  }

  changeHourlyMonthlyResults(hourlyMonthlyResultsReceived) {
    this.props.changeDynamicTabState({
      hourlyResults: hourlyMonthlyResultsReceived.HOURLY_RESOURCE_RATE_CALCULATION,
      monthlyResults: hourlyMonthlyResultsReceived.MONTHLY_RESOURCE_RATE_CALCULATION,
      totals: hourlyMonthlyResultsReceived.Totals,
    }, 'BlendedRateCalculation');
  }

  changeBlendedResults(selections, selectionsRolesListed) {
    this.props.changeDynamicTabState({
      blendedResults: {
        selections,
        selectionsRolesListed,
      },
    }, 'BlendedRateCalculation');
  }

  render() {
    return <div>{this.mainBody(this.props)}</div>;
  }
}

BlendedRateCalculation.propTypes = {
  dynamicTabState: PropTypes,
  changeDynamicTabState: PropTypes,
  billingCurrency: PropTypes.string,
};

export default BlendedRateCalculation;
