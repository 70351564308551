import XLSX from 'xlsx';
import * as UtilsFunction from './utils';
import {
  RATE_UP_HOUR_BILL_CTRL,
  RATE_UP_HOUR_RATE_FTE_CTRL,
} from './areaControl';

const dateTemp = Number(UtilsFunction.dateFormat(new Date(), 'yyyy')) + 70 + UtilsFunction.dateFormat(new Date(), '-MM-dd');
const dateNum = Number(new Date(dateTemp)) / 24 / 3600000 + 2;

const styleHeader = {
  font: {
    sz: 20,
    bold: true,
    color: {
      rgb: '4472C4',
    },
  },
};
const styleHorizCenter = {
  alignment: {
    wrapText: 1,
    horizontal: 'center',
  },
};
const styleAlignCenter = {
  alignment: {
    horizontal: 'center',
    vertical: 'center',
  },
};
const styleBorder = {
  border: {
    top: {
      style: 'thin',
    },
    bottom: {
      style: 'thin',
    },
    left: {
      style: 'thin',
    },
    right: {
      style: 'thin',
    },
  },
};
const styleItems = {
  font: {
    sz: 11,
  },
  border: {
    top: {
      style: 'thin',
    },
    bottom: {
      style: 'thin',
    },
    left: {
      style: 'thin',
    },
    right: {
      style: 'thin',
    },
  },
};
const styleCenterItems = {
  font: {
    sz: 11,
  },
  border: {
    top: {
      style: 'thin',
    },
    bottom: {
      style: 'thin',
    },
    left: {
      style: 'thin',
    },
    right: {
      style: 'thin',
    },
  },
  alignment: {
    horizontal: 'center',
  },
};
const styleTopTip = {
  font: {
    sz: 11,
  },
};
const styleTop2Title = {
  font: {
    sz: 11,
    bold: true,
    color: {
      rgb: 'ffffff',
    },
  },
  fill: {
    bgColor: {
      indexed: 64,
    },
    fgColor: {
      rgb: '4472C4',
    },
  },
  alignment: {
    wrapText: 1,
  },
};

const TopHeader = {
  A1: {
    v: 'Rate Calculator Result',
    s: styleHeader,
  },
  A2: {
    v: 'Date and Time Stamp',
    s: styleTopTip,
  },
  B2: {
    v: dateNum,
    t: 'n',
    z: 'yyyy-m-d',
    s: styleTopTip,
  },
};
const TopTitles = {
  A4: {
    v: 'Program Fields',
    s: {
      ...styleTop2Title,
      ...styleBorder,
    },
  },
  B4: {
    v: 'Input',
    s: {
      ...styleTop2Title,
      ...styleBorder,
    },
  },
  A5: {
    v: 'Business Area',
    s: styleItems,
  },
  A6: {
    v: 'Program Scenario Description',
    s: styleItems,
  },
  A7: {
    v: 'Billing Currency',
    s: styleItems,
  },
  A8: {
    v: 'Calendar Year',
    s: styleItems,
  },
  A9: {
    v: 'Communication Level',
    s: styleItems,
  },
  A10: {
    v: 'Domain/Technical Level',
    s: styleItems,
  },
  A11: {
    v: 'Resiliency',
    s: styleItems,
  },
  A12: {
    v: 'Include SuperNova?',
    s: styleItems,
  },
  A13: {
    v: 'Include WFM?',
    s: styleItems,
  },
};

function colLettersFunc(colLetters, rowMark1, titleNameArray) {
  let MidTitle2 = {};
  colLetters.forEach((col1, index1) => {
    MidTitle2 = {
      ...MidTitle2,
      ...{
        [col1 + rowMark1]: {
          v: titleNameArray[index1],
          s: {
            ...styleTop2Title,
            ...styleBorder,
          },
        },
      },
    };
  });
  return MidTitle2;
}

function rateupDownload(paramsFormObj, resultData, currentColShow, Currency) {
  const dataLength = resultData.length || 0;

  const TopInput = {
    B5: {
      v: paramsFormObj.ProgramName,
      s: styleItems,
    },
    B6: {
      v: paramsFormObj.Desription,
      s: styleItems,
    },
    B7: {
      v: paramsFormObj.billingCurrency,
      s: styleItems,
    },
    B8: {
      v: paramsFormObj.calendarYear ? new Date().getFullYear() : (new Date().getFullYear() + 1),
      s: styleItems,
    },
    B9: {
      v: paramsFormObj.CommunicationLevel,
      s: styleItems,
    },
    B10: {
      v: paramsFormObj.TechnicalLevel,
      s: styleItems,
    },
    B11: {
      v: paramsFormObj.Resiliency,
      s: styleItems,
    },
    B12: {
      v: paramsFormObj.IncludeSupernova,
      s: styleItems,
    },
    B13: {
      v: paramsFormObj.IncludeWFM,
      s: styleItems,
    },
  };
  const MidTitle = {
    C15: {
      v: 'Role Requirement Input',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    D15: {
      v: '',
      s: styleBorder,
    },
    E15: {
      v: '',
      s: styleBorder,
    },
    F15: {
      v: '',
      s: styleBorder,
    },
    G15: {
      v: 'Calculated Rate Output',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    H15: {
      v: '',
      s: styleBorder,
    },
    I15: {
      v: '',
      s: styleBorder,
    },
    J15: {
      v: '',
      s: styleBorder,
    },
    K15: {
      v: '',
      s: styleBorder,
    },
    L15: {
      v: '',
      s: styleBorder,
    },
    M15: {
      v: '',
      s: styleBorder,
    },
    N15: {
      v: '',
      s: styleBorder,
    },
  };
  const titleNameArray = ['#', 'Role', 'Level', 'Sub-Level', 'Location', 'Language', RATE_UP_HOUR_BILL_CTRL[paramsFormObj.ProgramName], 'Location Base Rate', 'Complexity Uplift', 'Language Tier Uplift', 'Resiliency', 'SuperNova', 'WFM', RATE_UP_HOUR_RATE_FTE_CTRL[paramsFormObj.ProgramName]];
  const colLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
  const rowMark1 = 16;
  const MidTitle2 = this.colLettersFunc(colLetters, rowMark1, titleNameArray);
  let dataMain = {};
  if (dataLength) {
    resultData.forEach((item, index) => {
      dataMain = {
        ...dataMain,
        ...{
          [colLetters[0] + (rowMark1 + 1 + index)]: {
            v: 1 + index,
            s: styleItems,
          },
          [colLetters[1] + (rowMark1 + 1 + index)]: {
            v: paramsFormObj.RSNames[index],
            s: styleItems,
          },
          [colLetters[2] + (rowMark1 + 1 + index)]: {
            v: paramsFormObj.Levels[index],
            s: styleItems,
          },
          [colLetters[3] + (rowMark1 + 1 + index)]: {
            v: paramsFormObj.SubLevels[index],
            s: styleItems,
          },
          [colLetters[4] + (rowMark1 + 1 + index)]: {
            v: paramsFormObj.Locations[index],
            s: styleItems,
          },
          [colLetters[5] + (rowMark1 + 1 + index)]: {
            v: paramsFormObj.Languages[index],
            s: styleItems,
          },
          [colLetters[6] + (rowMark1 + 1 + index)]: {
            v: item.BillableHours || '',
            s: styleItems,
          },
          [colLetters[7] + (rowMark1 + 1 + index)]: {
            v: currentColShow.locaBaseCol && UtilsFunction.checkRateTrans(item.LocationBaseRate) ? Currency + item.LocationBaseRate : '$0.00',
            s: styleItems,
          },
          [colLetters[8] + (rowMark1 + 1 + index)]: {
            v: currentColShow.ComUpliftCol && UtilsFunction.checkRateTrans(item.ComplexityUplift) ? Currency + item.ComplexityUplift : '$0.00',
            s: styleItems,
          },
          [colLetters[9] + (rowMark1 + 1 + index)]: {
            v: currentColShow.langTieCol && UtilsFunction.checkRateTrans(item.LanguageTierUplift) ? Currency + item.LanguageTierUplift : '$0.00',
            s: styleItems,
          },
          [colLetters[10] + (rowMark1 + 1 + index)]: {
            v: currentColShow.resCol && UtilsFunction.checkRateTrans(item.Resiliency) ? Currency + item.Resiliency : '$0.00',
            s: styleItems,
          },
          [colLetters[11] + (rowMark1 + 1 + index)]: {
            v: currentColShow.superCol && UtilsFunction.checkRateTrans(item.SuperNova) ? Currency + item.SuperNova : '$0.00',
            s: styleItems,
          },
          [colLetters[12] + (rowMark1 + 1 + index)]: {
            v: currentColShow.wfmCol && UtilsFunction.checkRateTrans(item.WFM) ? Currency + item.WFM : '$0.00',
            s: styleItems,
          },
          [colLetters[13] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.StandardHourlyRatePerFTE) ? Currency + item.StandardHourlyRatePerFTE : '',
            s: styleItems,
          },
        },
      };
    });
  }
  const workbook = {
    SheetNames: ['Sheet1'],
    Sheets: {
      Sheet1: {
        ...TopHeader,
        ...TopTitles,
        ...TopInput,
        ...MidTitle,
        ...MidTitle2,
        ...dataMain,
        '!cols': [{
          wch: 26.4,
        },
        {
          wch: 20.8,
        },
        {
          wch: 20.8,
        },
        {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        },
        {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        },
        {
          wch: 33.33,
        },
        ],
        '!rows': [
          ...[{
            hpt: 25.2,
          },
          {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          },
          {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          },
          {
            hpt: 27.6,
          },
          ],
          ...Array(dataLength).fill({
            hpt: 14.25,
          }),
        ],
        '!ref': `A1:N${rowMark1 + dataLength}`,
        '!merges': [{
          s: {
            c: 2,
            r: 14,
          },
          e: {
            c: 5,
            r: 14,
          },
        }, {
          s: {
            c: 6,
            r: 14,
          },
          e: {
            c: 13,
            r: 14,
          },
        }],
      },
    },
  };
  const wbName = `${paramsFormObj.ProgramName} Estimate ${UtilsFunction.dateFormat(new Date(), 'yyyyMMdd')}${dateNum}.xlsx`;
  XLSX.writeFile(workbook, wbName, {
    type: 'binary',
    bookSST: false,
    cellStyles: true,
  });
}

function monthlyRateDownload(paramsFormObj, resultData, baseData, Currency, total1, total2) {
  const dataLength = resultData.length || 0;

  const TopInput = {
    B5: {
      v: paramsFormObj.ProgramName,
      s: styleItems,
    },
    B6: {
      v: paramsFormObj.Desription,
      s: styleItems,
    },
    B7: {
      v: paramsFormObj.billingCurrency,
      s: styleItems,
    },
    B8: {
      v: paramsFormObj.calendarYear ? new Date().getFullYear() : (new Date().getFullYear() + 1),
      s: styleItems,
    },
    B9: {
      v: paramsFormObj.CommunicationLevel,
      s: styleItems,
    },
    B10: {
      v: paramsFormObj.TechnicalLevel,
      s: styleItems,
    },
    B11: {
      v: paramsFormObj.Resiliency,
      s: styleItems,
    },
    B12: {
      v: paramsFormObj.IncludeSupernova,
      s: styleItems,
    },
    B13: {
      v: paramsFormObj.IncludeWFM,
      s: styleItems,
    },
  };
  const MidTitle = {
    C15: {
      v: 'Role Requirement Input',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    D15: {
      v: '',
      s: styleBorder,
    },
    E15: {
      v: '',
      s: styleBorder,
    },
    F15: {
      v: '',
      s: styleBorder,
    },
    G15: {
      v: '',
      s: styleBorder,
    },
    H15: {
      v: 'Calculated Rate Output',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    I15: {
      v: '',
      s: styleBorder,
    },
    J15: {
      v: '',
      s: styleBorder,
    },
    K15: {
      v: '',
      s: styleBorder,
    },
    L15: {
      v: '',
      s: styleBorder,
    },
    M15: {
      v: '',
      s: styleBorder,
    },
  };
  let MidTitle2 = {};
  const colLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
  const rowMark1 = 16;
  const titleNameArray = ['#', 'Role', 'FTE Count', 'Overtime', 'Night Shift', 'Weekend', 'Public Holidays', 'Standard Hourly Rate per FTE',
    'Overtime Hourly Rate per FTE', 'Night Shift Hourly Rate per FTE', 'Weekend Hourly Rate per FTE', 'Public Holidays Hourly Rate per FTE',
    'Estimated Monthly Run Rate',
  ];
  colLetters.forEach((col1, index1) => {
    MidTitle2 = {
      ...MidTitle2,
      ...{
        [col1 + rowMark1]: {
          v: titleNameArray[index1],
          s: {
            ...styleTop2Title,
            ...styleBorder,
            ...styleHorizCenter,
          },
        },
      },
    };
  });
  let dataMain = {};
  if (dataLength) {
    resultData.forEach((item, index) => {
      dataMain = {
        ...dataMain,
        ...{
          [colLetters[0] + (rowMark1 + 1 + index)]: {
            v: 1 + index,
            s: styleCenterItems,
          },
          [colLetters[1] + (rowMark1 + 1 + index)]: {
            v: paramsFormObj.RSNames[index],
            s: styleCenterItems,
          },
          [colLetters[2] + (rowMark1 + 1 + index)]: {
            v: baseData[index].FTECount2,
            s: styleCenterItems,
          },
          [colLetters[3] + (rowMark1 + 1 + index)]: {
            v: baseData[index].Overtime,
            s: styleCenterItems,
          },
          [colLetters[4] + (rowMark1 + 1 + index)]: {
            v: baseData[index].NightShift,
            s: styleCenterItems,
          },
          [colLetters[5] + (rowMark1 + 1 + index)]: {
            v: baseData[index].Weekend,
            s: styleCenterItems,
          },
          [colLetters[6] + (rowMark1 + 1 + index)]: {
            v: baseData[index].PublicHolidays,
            s: styleCenterItems,
          },

          [colLetters[7] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.StandardHourlyRatePerFTE) ? Currency + item.StandardHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colLetters[8] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.OvertimeHourlyRatePerFTE) ? Currency + item.OvertimeHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colLetters[9] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.NightShiftHourlyRatePerFTE) ? Currency + item.NightShiftHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colLetters[10] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.WeekendHourlyRatePerFTE) ? Currency + item.WeekendHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colLetters[11] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.PublicHolidaysHourlyRatePerFTE) ? Currency + item.PublicHolidaysHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colLetters[12] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.EstimatedMonthlyRunRate) ? `${Currency + item.EstimatedMonthlyRunRate}M` : '',
            s: styleCenterItems,
          },
        },
      };
    });
  }
  const dataTotals = {
    [`B${rowMark1 + dataLength + 2}`]: {
      v: 'FTE Total',
      s: styleTopTip,
    },
    [`C${rowMark1 + dataLength + 2}`]: {
      v: total1,
      s: styleItems,
    },
    [`L${rowMark1 + dataLength + 2}`]: {
      v: 'Total Estimated Monthly Run Rate',
      s: styleTopTip,
    },
    [`M${rowMark1 + dataLength + 2}`]: {
      v: total2,
      s: styleItems,
    },
  };
  const workbook = {
    SheetNames: ['Sheet1'],
    Sheets: {
      Sheet1: {
        ...TopHeader,
        ...TopTitles,
        ...TopInput,
        ...MidTitle,
        ...MidTitle2,
        ...dataMain,
        ...dataTotals,
        '!cols': [{
          wch: 26.4,
        },
        {
          wch: 20.8,
        },
        {
          wch: 20.8,
        },
        {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        },
        {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 18.8,
        },
        ],
        '!rows': [
          ...[{
            hpt: 25.2,
          },
          {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          },
          {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          },
          {
            hpt: 27.6,
          },
          ],
          ...Array(dataLength).fill({
            hpt: 14.25,
          }), ...[{
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }],
        ],
        '!ref': `A1:M${rowMark1 + dataLength + 2}`,
        '!merges': [{
          e: {
            c: 6,
            r: 14,
          },
          s: {
            c: 2,
            r: 14,
          },
        },
        {
          e: {
            c: 12,
            r: 14,
          },
          s: {
            c: 7,
            r: 14,
          },
        },
        ],
      },
    },
  };
  const wbName = `${paramsFormObj.ProgramName} Estimate ${UtilsFunction.dateFormat(new Date(), 'yyyyMMdd')}${dateNum}.xlsx`;
  XLSX.writeFile(workbook, wbName, {
    type: 'binary',
    bookSST: false,
    cellStyles: true,
  });
}

function blendDownload(
  paramsFormObj,
  blendParamsObj,
  emptyBlendTop,
  resultData1,
  resultData2,
  baseData,
  Currency,
  totalFTE,
  totalBillK,
  totalEstM,
  totalImpliedBlend,
) {
  const dataLength = resultData1.length || 0;

  const TopInput = {
    B5: {
      v: paramsFormObj.ProgramName,
      s: styleItems,
    },
    B6: {
      v: paramsFormObj.Desription,
      s: styleItems,
    },
    B7: {
      v: paramsFormObj.billingCurrency,
      s: styleItems,
    },
    B8: {
      v: paramsFormObj.calendarYear ? new Date().getFullYear() : (new Date().getFullYear() + 1),
      s: styleItems,
    },
    B9: {
      v: paramsFormObj.CommunicationLevel,
      s: styleItems,
    },
    B10: {
      v: paramsFormObj.TechnicalLevel,
      s: styleItems,
    },
    B11: {
      v: paramsFormObj.Resiliency,
      s: styleItems,
    },
    B12: {
      v: paramsFormObj.IncludeSupernova,
      s: styleItems,
    },
    B13: {
      v: paramsFormObj.IncludeWFM,
      s: styleItems,
    },
  };
  const MidTitle = {
    C15: {
      v: 'Role Requirement Input',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    D15: {
      v: '',
      s: styleBorder,
    },
    E15: {
      v: '',
      s: styleBorder,
    },
    F15: {
      v: '',
      s: styleBorder,
    },
    G15: {
      v: 'Calculated Rate Output',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    H15: {
      v: '',
      s: styleBorder,
    },
    I15: {
      v: '',
      s: styleBorder,
    },
    J15: {
      v: '',
      s: styleBorder,
    },
    K15: {
      v: '',
      s: styleBorder,
    },
    L15: {
      v: '',
      s: styleBorder,
    },
    M15: {
      v: '',
      s: styleBorder,
    },
    N15: {
      v: '',
      s: styleBorder,
    },
  };
  const titleNameArray = ['#', 'Role', 'Level', 'Sub-Level', 'Location', 'Language', 'Billable Hours', 'Location Base Rate',
    'Complexity Uplift', 'Language Tier Uplift', 'Resiliency', 'SuperNova', 'WFM', 'Standard Hourly Rate per FTE',
  ];
  const colLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N'];
  const rowMark1 = 16;
  const MidTitle2 = this.colLettersFunc(colLetters, rowMark1, titleNameArray);
  let dataMain = {};
  if (dataLength) {
    resultData1.forEach((item, index) => {
      dataMain = {
        ...dataMain,
        ...{
          [colLetters[0] + (rowMark1 + 1 + index)]: {
            v: 1 + index,
            s: styleItems,
          },
          [colLetters[1] + (rowMark1 + 1 + index)]: {
            v: baseData[index].Role,
            s: styleItems,
          },
          [colLetters[2] + (rowMark1 + 1 + index)]: {
            v: baseData[index].Level,
            s: styleItems,
          },
          [colLetters[3] + (rowMark1 + 1 + index)]: {
            v: baseData[index].SubLevel,
            s: styleItems,
          },
          [colLetters[4] + (rowMark1 + 1 + index)]: {
            v: blendParamsObj.Location,
            s: styleItems,
          },
          [colLetters[5] + (rowMark1 + 1 + index)]: {
            v: blendParamsObj.Language,
            s: styleItems,
          },

          [colLetters[6] + (rowMark1 + 1 + index)]: {
            v: item.BillableHours,
            s: styleItems,
          },
          [colLetters[7] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.LocationBaseRate) ? Currency + item.LocationBaseRate : '',
            s: styleItems,
          },
          [colLetters[8] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.ComplexityUplift) ? Currency + item.ComplexityUplift : '',
            s: styleItems,
          },
          [colLetters[9] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.LanguageTierUplift) ? Currency + item.LanguageTierUplift : '',
            s: styleItems,
          },
          [colLetters[10] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.Resiliency) ? Currency + item.Resiliency : '',
            s: styleItems,
          },
          [colLetters[11] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.SuperNova) ? Currency + item.SuperNova : '',
            s: styleItems,
          },
          [colLetters[12] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.WFM) ? Currency + item.WFM : '',
            s: styleItems,
          },
          [colLetters[13] + (rowMark1 + 1 + index)]: {
            v: UtilsFunction.checkRateTrans(item.StandardHourlyRatePerFTE) ? Currency + item.StandardHourlyRatePerFTE : '',
            s: styleItems,
          },
        },
      };
    });
  }

  const BlendTitle = {
    C1: {
      v: 'Role Requirement Input',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    D1: {
      v: '',
      s: styleBorder,
    },
    E1: {
      v: '',
      s: styleBorder,
    },
    F1: {
      v: '',
      s: styleBorder,
    },
    G1: {
      v: '',
      s: styleBorder,
    },
    H1: {
      v: 'Calculated Rate Output',
      s: {
        ...styleTop2Title,
        ...styleBorder,
        ...styleAlignCenter,
      },
    },
    I1: {
      v: '',
      s: styleBorder,
    },
    J1: {
      v: '',
      s: styleBorder,
    },
    K1: {
      v: '',
      s: styleBorder,
    },
    L1: {
      v: '',
      s: styleBorder,
    },
    M1: {
      v: '',
      s: styleBorder,
    },
  };
  let BlendTitle2 = {};
  const colBlendLetters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M'];
  const rowMark2 = 2;
  const titleNameArray2 = ['#', 'Role', 'FTE Count', 'Overtime', 'Night Shift', 'Weekend', 'Public Holidays',
    'Standard Hourly Rate per FTE', 'Overtime Hourly Rate per FTE', 'Night Shift Hourly Rate per FTE',
    'Weekend Hourly Rate per FTE', 'Public Holidays Hourly Rate per FTE', 'Estimated Monthly Run Rate',
  ];
  colBlendLetters.forEach((col1, index1) => {
    BlendTitle2 = {
      ...BlendTitle2,
      ...{
        [col1 + rowMark2]: {
          v: titleNameArray2[index1],
          s: {
            ...styleTop2Title,
            ...styleBorder,
            ...styleHorizCenter,
          },
        },
      },
    };
  });
  let dataMain2 = {};
  if (dataLength) {
    resultData2.forEach((item2, index2) => {
      dataMain2 = {
        ...dataMain2,
        ...{
          [colBlendLetters[0] + (rowMark2 + 1 + index2)]: {
            v: 1 + index2,
            s: styleCenterItems,
          },
          [colBlendLetters[1] + (rowMark2 + 1 + index2)]: {
            v: item2.RoleNameDescription,
            s: styleItems,
          },
          [colBlendLetters[2] + (rowMark2 + 1 + index2)]: {
            v: item2.FTECount,
            s: styleCenterItems,
          },
          [colBlendLetters[3] + (rowMark2 + 1 + index2)]: {
            v: emptyBlendTop[0].Overtime,
            s: styleCenterItems,
          },
          [colBlendLetters[4] + (rowMark2 + 1 + index2)]: {
            v: emptyBlendTop[0].NightShift,
            s: styleCenterItems,
          },
          [colBlendLetters[5] + (rowMark2 + 1 + index2)]: {
            v: emptyBlendTop[0].Weekend,
            s: styleCenterItems,
          },
          [colBlendLetters[6] + (rowMark2 + 1 + index2)]: {
            v: emptyBlendTop[0].PublicHolidays,
            s: styleCenterItems,
          },

          [colBlendLetters[7] + (rowMark2 + 1 + index2)]: {
            v: UtilsFunction.checkRateTrans(item2.StandardHourlyRatePerFTE) ? Currency + item2.StandardHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colBlendLetters[8] + (rowMark2 + 1 + index2)]: {
            v: UtilsFunction.checkRateTrans(item2.OvertimeHourlyRatePerFTE) ? Currency + item2.OvertimeHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colBlendLetters[9] + (rowMark2 + 1 + index2)]: {
            v: UtilsFunction.checkRateTrans(item2.NightShiftHourlyRatePerFTE) ? Currency + item2.NightShiftHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colBlendLetters[10] + (rowMark2 + 1 + index2)]: {
            v: UtilsFunction.checkRateTrans(item2.WeekendHourlyRatePerFTE) ? Currency + item2.WeekendHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colBlendLetters[11] + (rowMark2 + 1 + index2)]: {
            v: UtilsFunction.checkRateTrans(item2.PublicHolidayHourlyRatePerFTE) ? Currency + item2.PublicHolidayHourlyRatePerFTE : '',
            s: styleCenterItems,
          },
          [colBlendLetters[12] + (rowMark2 + 1 + index2)]: {
            v: UtilsFunction.checkRateTrans(item2.EstimatedMonthlyRunRate) ? `${Currency + item2.EstimatedMonthlyRunRate}M` : '',
            s: styleCenterItems,
          },
        },
      };
    });
  }
  const dataTotals2 = {
    [`B${rowMark2 + dataLength + 2}`]: {
      v: 'Total FTES',
      s: styleTopTip,
    },
    [`C${rowMark2 + dataLength + 2}`]: {
      v: totalFTE,
      s: styleItems,
    },
    [`B${rowMark2 + dataLength + 4}`]: {
      v: 'Total Billable Hours (K)',
      s: styleTopTip,
    },
    [`C${rowMark2 + dataLength + 4}`]: {
      v: totalBillK,
      s: styleItems,
    },

    [`L${rowMark2 + dataLength + 2}`]: {
      v: 'Total Estimated Monthly Run Rate',
      s: styleTopTip,
    },
    [`M${rowMark2 + dataLength + 2}`]: {
      v: totalEstM,
      s: styleItems,
    },
    [`L${rowMark2 + dataLength + 4}`]: {
      v: 'Implied Blended Rate per Billable Resource per Hour',
      s: styleTopTip,
    },
    [`M${rowMark2 + dataLength + 4}`]: {
      v: totalImpliedBlend,
      s: styleItems,
    },
  };
  const workbook = {
    SheetNames: ['Rates per Role', 'Blended Rate'],
    Sheets: {
      'Rates per Role': {
        ...TopHeader,
        ...TopTitles,
        ...TopInput,
        ...MidTitle,
        ...MidTitle2,
        ...dataMain,
        '!cols': [{
          wch: 26.4,
        },
        {
          wch: 20.8,
        },
        {
          wch: 20.8,
        },
        {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        },
        {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        }, {
          wch: 20.8,
        },
        {
          wch: 8.1,
        },
        ],
        '!rows': [
          ...[{
            hpt: 25.2,
          },
          {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          },
          {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          }, {
            hpt: 14.4,
          },
          {
            hpt: 27.6,
          },
          ],
          ...Array(dataLength).fill({
            hpt: 14.25,
          }),
        ],
        '!ref': `A1:N${rowMark1 + dataLength}`,
        '!merges': [{
          s: {
            c: 2,
            r: 14,
          },
          e: {
            c: 5,
            r: 14,
          },
        }, {
          s: {
            c: 6,
            r: 14,
          },
          e: {
            c: 13,
            r: 14,
          },
        }],
      },
      'Blended Rate': {
        ...BlendTitle,
        ...BlendTitle2,
        ...dataMain2,
        ...dataTotals2,
        '!cols': [{
          wch: 8.1,
        }, {
          wch: 18.46,
        }, {
          wch: 10.23,
        }, {
          wch: 10.9,
        }, {
          wch: 10.46,
        }, {
          wch: 9.23,
        }, {
          wch: 14.9,
        },
        {
          wch: 17.01,
        }, {
          wch: 15.68,
        }, {
          wch: 18.01,
        }, {
          wch: 18.12,
        }, {
          wch: 28.9,
        }, {
          wch: 17.79,
        },
        ],
        '!rows': [
          ...[{
            hpt: 14.4,
          }, {
            hpt: 31.1,
          }],
          ...Array(dataLength).fill({
            hpt: 14.25,
          }),
          ...Array(4).fill({
            hpt: 14.25,
          }),
        ],
        '!ref': `A1:M${rowMark2 + dataLength + 4}`,
        '!merges': [{
          s: {
            c: 2,
            r: 0,
          },
          e: {
            c: 6,
            r: 0,
          },
        },
        {
          s: {
            c: 7,
            r: 0,
          },
          e: {
            c: 12,
            r: 0,
          },
        },
        ],
      },
    },
  };
  const wbName = `${paramsFormObj.ProgramName} Estimate ${UtilsFunction.dateFormat(new Date(), 'yyyyMMdd')}${dateNum}.xlsx`;
  XLSX.writeFile(workbook, wbName, {
    type: 'binary',
    bookSST: false,
    cellStyles: true,
  });
}
export default {
  rateupDownload,
  monthlyRateDownload,
  blendDownload,
  colLettersFunc,
};
