let AMAZON_API_BASE_VALUE;
let AMAZON_API_PDF_BASE_VALUE;
if (process.env.REACT_APP_ENVIROMENT === 'production') {
  AMAZON_API_BASE_VALUE = 'https://xk8gb94fq8.execute-api.us-east-1.amazonaws.com/prod';
  AMAZON_API_PDF_BASE_VALUE = 'https://xk8gb94fq8.execute-api.us-east-1.amazonaws.com/prod/get-data/ui-data?file=';
} else {
  AMAZON_API_BASE_VALUE = 'https://ieawood740.execute-api.us-east-1.amazonaws.com/dev';
  AMAZON_API_PDF_BASE_VALUE = 'https://ieawood740.execute-api.us-east-1.amazonaws.com/dev/get-data/ui-data?file=';
}

export const AMAZON_API_BASE = AMAZON_API_BASE_VALUE;
export const AMAZON_API_PDF_BASE = AMAZON_API_PDF_BASE_VALUE;

export const hourlyRateCalcColumnAttributes = [
  {
    letter: 'B',
    title: 'Location Base Rate',
    rowKey: 'LocationBaseRate',
    titleKey: 'locationBaseCol',
  },
  {
    letter: 'C',
    title: 'Complexity Uplift',
    rowKey: 'ComplexityUplift',
    titleKey: 'compCol',
  },
  {
    letter: 'D',
    title: 'Language Tier Uplift',
    rowKey: 'LanguageTierUplift',
    titleKey: 'langTieCol',
  },
  {
    letter: 'E',
    title: 'Resiliency',
    rowKey: 'Resiliency',
    titleKey: 'resiliencyCol',
  },
  {
    letter: 'F',
    title: 'SuperNova',
    rowKey: 'SuperNova',
    titleKey: 'superNovaCol',
  },
  {
    letter: 'G',
    title: 'WFM',
    rowKey: 'WFM',
    titleKey: 'wfmCol',
  },
];

export const initialSelectionsData = {
  none: {
    tabs: [
      {
        name: 'First Tab',
        url: 'https://people.sc.fsu.edu/~jburkardt/data/csv/addresses.csv',
      },
    ],
  },
  'Content Review': {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'includeComplexity', 'locationCol', 'langCol'],
    hourlyRateCalcColumn: ['B', 'C', 'D', 'E', 'F', 'G'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/Content Review Template.csv`,
        section: 1,
        pdfUrl: `${AMAZON_API_PDF_BASE}content_review_base_rates.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/Content Review Template.csv`,
        section: 2,
        pdfUrl: `${AMAZON_API_PDF_BASE}content_review_level_definitions.pdf`,
      },
      {
        name: 'Complexity Multipliers',
        url: `${AMAZON_API_BASE}/calculation-data/Content Review Template.csv`,
        section: 3,
        pdfUrl: `${AMAZON_API_PDF_BASE}content_review_complexity_multipliers.pdf`,
      },
      {
        name: 'Language Premium Matrix',
        url: `${AMAZON_API_BASE}/calculation-data/Language Premium Matrix.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}language_premium_matrix.pdf`,
      },
      {
        name: 'Resiliency',
        url: `${AMAZON_API_BASE}/calculation-data/Content Review Template.csv`,
        section: 5,
        pdfUrl: `${AMAZON_API_PDF_BASE}content_review_resiliency.pdf`,
      },
      {
        name: 'Shift Multipliers',
        url: `${AMAZON_API_BASE}/calculation-data/Shift Multipliers.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}content_review_shift_multipliers.pdf`,
      },
      {
        name: 'Billable Hours',
        url: `${AMAZON_API_BASE}/calculation-data/Content Review Template.csv`,
        section: 6,
        pdfUrl: `${AMAZON_API_PDF_BASE}content_review_billable_hours.pdf`,
      },
      {
        name: 'COLA',
        url: `${AMAZON_API_BASE}/calculation-data/Cost Of Living Adjustment (COLA) by Location.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}cola.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
      {
        name: 'Standard Ratio',
        url: `${AMAZON_API_BASE}/calculation-data/Standard Ratio.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}standard_ratio.pdf`,
      },
    ],
    preContentText: 'The blue tabs below provide the ability look up rates by role (Rate Look Up), calculate a monthly run rate for one or more roles (Monthly Run Rate), and customize a blended rate (Blended Rate Calculation). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp', 'MonthlyRunRate', 'BlendedRateCalculation'],
    programDetails: ['desription', 'billingCurrency', 'calendarYear'],
    programPricingFactors: ['communicationLevel', 'technicalLevel', 'resiliency', 'includeSupernova', 'includeWFM'],
  },
  PSO: {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol', 'langCol'],
    hourlyRateCalcColumn: ['B', 'D', 'F', 'G'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/PSO Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}pso_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/PSO Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}pso_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/PSO Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}pso_level_definitions.pdf`,
      },
      {
        name: 'Language Premium Matrix',
        url: `${AMAZON_API_BASE}/calculation-data/Language Premium Matrix.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}language_premium_matrix.pdf`,
      },
      {
        name: 'Shift Multipliers',
        url: `${AMAZON_API_BASE}/calculation-data/Shift Multipliers.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}pso_shift_multipliers.pdf`,
      },
      {
        name: 'COLA',
        url: `${AMAZON_API_BASE}/calculation-data/Cost Of Living Adjustment (COLA) by Location.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}cola.pdf`,

      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
      {
        name: 'SuperNova',
        url: `${AMAZON_API_BASE}/calculation-data/SuperNova.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}supernova.pdf`,
      },
      {
        name: 'WFM',
        url: `${AMAZON_API_BASE}/calculation-data/WFM.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}wfm.pdf`,
      },
    ],
    preContentText: 'The blue tabs below provide the ability look up rates by role (Rate Look Up) and calculate a monthly run rate for one or more roles (Monthly Run Rate). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp', 'MonthlyRunRate'],
    programDetails: ['desription', 'billingCurrency', 'calendarYear'],
    programPricingFactors: ['includeSupernova', 'includeWFM'],
  },
  Marketing: {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol'],
    hourlyRateCalcColumn: ['B'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/Marketing Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}marketing_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/Marketing Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}marketing_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/Marketing Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}marketing_level_definitions.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
    ],
    preContentText: 'The blue tab below provides the ability look up rates by role (Rate Look Up). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp'],
    programDetails: ['desription', 'billingCurrency'],
    programPricingFactors: [],
  },
  Consulting: {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol'],
    hourlyRateCalcColumn: ['B'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/Consulting Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}consulting_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/Consulting Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}consulting_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/Consulting Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}consulting_level_definitions.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
    ],
    preContentText: 'The blue tab below provides the ability look up rates by role (Rate Look Up). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp'],
    programDetails: ['desription', 'billingCurrency'],
    programPricingFactors: [],
  },
  Infrastructure: {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol'],
    hourlyRateCalcColumn: ['B'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/Infrastructure Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}infrastructure_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/Infrastructure Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}infrastructure_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/Infrastructure Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}infrastructure_level_definitions.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
    ],
    preContentText: 'The blue tab below provides the ability look up rates by role (Rate Look Up). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp'],
    programDetails: ['desription', 'billingCurrency'],
    programPricingFactors: [],
  },
  'HR Operations': {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol', 'langCol'],
    hourlyRateCalcColumn: ['B', 'D', 'F', 'G'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/HR Operations Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}hr_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/HR Operations Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}hr_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/HR Operations Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}hr_level_definitions.pdf`,
      },
      {
        name: 'Language Premium Matrix',
        url: `${AMAZON_API_BASE}/calculation-data/Language Premium Matrix.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}language_premium_matrix.pdf`,
      },
      {
        name: 'COLA',
        url: `${AMAZON_API_BASE}/calculation-data/Cost Of Living Adjustment (COLA) by Location.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}cola.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
    ],
    preContentText: 'The blue tab below provides the ability look up rates by role (Rate Look Up). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp'],
    programDetails: ['desription', 'billingCurrency', 'calendarYear'],
    programPricingFactors: ['includeSupernova', 'includeWFM'],
  },
  Legal: {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol', 'langCol'],
    hourlyRateCalcColumn: ['B', 'D', 'E', 'F', 'G'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/Legal Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}legal_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/Legal Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}legal_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/Legal Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}legal_level_definitions.pdf`,
      },
      {
        name: 'Language Premium Matrix',
        url: `${AMAZON_API_BASE}/calculation-data/Language Premium Matrix.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}language_premium_matrix.pdf`,
      },
      {
        name: 'COLA',
        url: `${AMAZON_API_BASE}/calculation-data/Cost Of Living Adjustment (COLA) by Location.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}cola.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
    ],
    preContentText: 'The blue tab below provides the ability look up rates by role (Rate Look Up). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp'],
    programDetails: ['desription', 'billingCurrency', 'calendarYear'],
    programPricingFactors: ['resiliency', 'includeSupernova', 'includeWFM'],
  },
  'Finance & Accounting Outsourcing': {
    resourceRequirementCols: ['roleCol', 'levelCol', 'subLevelCol', 'locationCol', 'langCol'],
    hourlyRateCalcColumn: ['B', 'D', 'F', 'G'],
    tabs: [
      {
        name: 'Base Rates',
        url: `${AMAZON_API_BASE}/calculation-data/Finance & Accounting Outsourcing Base Rates.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}finance_base_rates.pdf`,
      },
      {
        name: 'Billing Units',
        url: `${AMAZON_API_BASE}/calculation-data/Finance & Accounting Outsourcing Billing Units.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}finance_billing_units.pdf`,
      },
      {
        name: 'Level Definitions',
        url: `${AMAZON_API_BASE}/calculation-data/Finance & Accounting Outsourcing Level Definitions.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}finance_level_definitions.pdf`,
      },
      {
        name: 'Language Premium Matrix',
        url: `${AMAZON_API_BASE}/calculation-data/Language Premium Matrix.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}language_premium_matrix.pdf`,
      },
      {
        name: 'COLA',
        url: `${AMAZON_API_BASE}/calculation-data/Cost Of Living Adjustment (COLA) by Location.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}cola.pdf`,
      },
      {
        name: 'FX',
        url: `${AMAZON_API_BASE}/calculation-data/FX by Billable Currency.csv`,
        pdfUrl: `${AMAZON_API_PDF_BASE}fx.pdf`,
      },
    ],
    preContentText: 'The blue tab below provides the ability look up rates by role (Rate Look Up). The white tabs contain negotiated rate components and other reference data used in the calculation tabs.',
    dynamicTabs: ['RateLookUp'],
    programDetails: ['desription', 'billingCurrency', 'calendarYear'],
    programPricingFactors: ['includeSupernova', 'includeWFM'],
  },
};

export const subSelectionsData = {
  desription: {
    type: 'textBox',
    label: 'Program/Scenario Description (optional)',
    title: 'proOption',
  },
  billingCurrency: {
    type: 'radioButtons',
    label: 'Billing Currency',
    selections: ['USD', 'EUR', 'INR'],
    title: 'billCurrency',
  },
  calendarYear: {
    type: 'radioButtons',
    label: 'Calendar Year',
    selections: [{ customLabel: 'Current', customValue: 'No' }, { customLabel: 'Next', customValue: 'Yes' }],
    title: 'calendarYear',
  },
  communicationLevel: {
    type: 'radioButtons',
    label: 'Communication Level',
    selections: ['Low', 'Medium', 'High'],
    title: 'communicateLevel',
  },
  technicalLevel: {
    type: 'radioButtons',
    label: 'Domain/Technical Level',
    selections: ['Low', 'Medium', 'High'],
    title: 'techLevel',
  },
  resiliency: {
    type: 'radioButtons',
    label: 'Resiliency',
    selections: ['None', 'Category 1', 'Category 2'],
    title: 'resiliency',
  },
  includeSupernova: {
    type: 'radioButtons',
    label: 'Include Supernova?',
    selections: ['Yes', 'No'],
    title: 'inSupernova',
  },
  includeWFM: {
    type: 'radioButtons',
    label: 'Include WFM?',
    selections: ['Yes', 'No'],
    title: 'inwfm',
  },
};

export const TOOL_TIP_MSG = {
  userGuide: 'Click here to open the Rate Calculator User Guide',
  businessArea: 'Select a Business Area. Only authorized Business Areas can be selected.',
  proOption: 'OPTIONAL - Enter text to identify the scenario or rate to be calculated.',
  billCurrency: 'Select a Billing Currency. Rates will be displayed in the currency selected.',
  calendarYear: 'Select a Calendar Year.  Rates displayed will be valid only for the year selected.',
  communicateLevel: 'Select a Communication Level.  Descriptions of High, Medium, and Low can be found on the Complexity Multipliers tab below.',
  comLow: 'Minimal external customer communication (structured communications only) + Minimal Internal Facebook communication (English proficiency level - see English Proficiency tab)',
  comMedium: 'Minimal external customer communication + Regular Internal Facebook communication (English proficiency level - see English Proficiency tab.',
  comHigh: 'Daily external customer communication required (English proficiency level - see English Proficiency tab)',
  techLevel: 'Select a Domain Level.  Descriptions of High, Medium, and Low can be found on the Complexity Multipliers tab below.',
  techLow: 'No specialty skills required',
  techMedium: '- No specific degree required (may require a non-specialty certification) but requires > 1 years skills & experience in field\n'
    + '- Increased level of complexity in processes and expectations required by the business',
  techHigh: '- Specific degree required (e.g. Journalism, Marketing, Accounting), special certification required, and/or >5 years skills & experience in field\n'
    + '- High level of complexity in processes and outcomes required by the business',
  resiliency: 'Select the level of Resiliency for the Program to be included in the calculated rates.',
  topRadioDisabled: 'Did you want to change one of the Program Details or Pricing Factors? Please return to the Rate Look Up tab to change these settings and resubmit the rates for lookup before completing this Monthly Run Rate tab.',
  resNone: 'Resiliency not applicable.',
  resCate1: 'Comprehensive Support',
  resCate2: 'General Support',
  inSupernova: 'Is SuperNova applicable?',
  inwfm: 'Is WFM applicable?',
  rateEst: 'Use this tab to calculate rates for selectable roles.',
  roleCol: 'Select a role to calculate a rate.  All pre-defined roles are listed.  Blended can be selected to get a singe blended rate for a location. Custom can be selected to define a "new" roles based on selection of level and sub-level.',
  levelCol: 'Level is provided for pre-defined roles.  A Blended role does not have a level. For a Custom role, SELECT the Level to receive a calculated rate.',
  subLevelCol: 'Sub-level is provided for pre-defined roles.  A Blended role does not have a sub-level. For a Custom role, SELECT the Sub-Level to receive a calculated rate.',
  locationCol: 'Select a Location to receive a calculated rate for this role.',
  langCol: 'Select a Language to receive a calculated rate for this role in the selected Location.  Only available languages in that location can be selected.',
  clearAll: 'Click here to clear all role, level, sub-level, location, and language selections.',
  submit: 'Click here to calculate the rate for the selected role, level, sub-level, location, and language with the program details and factors.',
  yearCol: 'Year for which rates have been calculated.',
  hoursCol: 'Billable hours for each FTE for the role defined.',
  locationBaseCol: 'Base hourly rate for the specified level, sub-level, and language in this location including COLA and FX.',
  compCol: 'Uplift to rate associated with the complexity level for this location.',
  langTieCol: 'Uplift to rate associated with language tier.',
  resiliencyCol: 'Uplift to rate associated with the resiliency category for this location.',
  superNovaCol: 'Uplift to rate associated with SuperNova for this location.',
  wfmCol: 'Uplift to rate associated with WFM for this location.',
  standardCol: 'Calculated hourly rate per FTE for the selected role.',
  downloadBtn: 'Click here to download the user input and calculated rates in an Excel file.',
  includeComplexity: 'Checking this box will include the Complexity Uplift value calculated from the Communication and Technical Complexity chosen above. Unchecking it will set the Complexity Uplift to 0.',
};

export const LANG_LIST_NEW = {
  Manila: ['Arabic - Gulf', 'Burmese', 'Cantonese', 'English', 'Filipino', 'Hindi', 'Indonesian', 'Japanese', 'Korean', 'Malaysian', 'Mandarin', 'Thai', 'Vietnamese'],
  Mumbai: ['English', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)'],
  'Hyderabad - ACN': ['English', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)'],
  'Hyderabad - FB': ['English', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)'],
  'Bangalore - ACN': ['English', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)'],
  'Bangalore - FB': ['English', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)'],
  MVW: ['Arabic - Gulf', 'English', 'Filipino', 'French', 'German', 'Hindi', 'Indonesian', 'Italian', 'Mandarin', 'Portuguese', 'Spanish', 'Thai', 'Turkish', 'Vietnamese'],
  Austin: ['English', 'German', 'Portuguese', 'Spanish'],
  Warsaw: ['Arabic - Gulf', 'Bosnian', 'Catalan', 'Croatian', 'Czech / Slovak', 'Danish', 'Dutch', 'English', 'French', 'German', 'Greek', 'Hebrew', 'Italian', 'Lithuanian', 'Macedonian', 'Norwegian', 'Polish', 'Portuguese', 'Russian', 'Serbian', 'Slovenian', 'Spanish', 'Swedish', 'Turkish'],
  'Kuala Lumpur': ['Arabic - Egyptian', 'Arabic - Gulf', 'Burmese', 'Cambodian', 'Cantonese', 'English', 'Hong Kong (Market)', 'Indonesian', 'Japanese', 'Korean', 'Laos', 'Malaysian', 'Mandarin', 'Taiwan (Market)', 'Tausug', 'Thai', 'Vietnamese', 'Sinhalese'],
  Lisbon: ['Dutch', 'English', 'French', 'German', 'Italian', 'Portuguese', 'Russian', 'Spanish', 'Swedish', 'Turkish'],
  'Dublin - ACN': ['Arabic - Gulf', 'Danish', 'Dutch', 'English', 'French', 'German', 'Hebrew', 'Hindi', 'Indonesian', 'Italian', 'Japanese', 'Korean', 'Malaysian', 'Mandarin', 'Norwegian', 'Polish', 'Portuguese', 'Russian', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)', 'Spanish', 'Swedish', 'Thai', 'Turkish', 'Vietnamese'],
  'Dublin - FB': ['Arabic - Gulf', 'Danish', 'Dutch', 'English', 'French', 'German', 'Hebrew', 'Hindi', 'Indonesian', 'Italian', 'Japanese', 'Korean', 'Malaysian', 'Mandarin', 'Norwegian', 'Polish', 'Portuguese', 'Russian', 'South Asian (Hindi, Bengali, Tamil, Malayalam, Urdu, Marathi, Punjabi, Telugu, Gujarati, Kannada, Oriya, Sindhi, Kashmiri, Asamese)', 'Spanish', 'Swedish', 'Thai', 'Turkish', 'Vietnamese'],
  Singapore: ['Bahasa', 'Burmese', 'Cantonese', 'English', 'Hindi', 'Japanese', 'Korean', 'Malayalam', 'Simplified Chinese', 'Tamil', 'Thai', 'Vietnamese'],
  Heredia: ['English'],
  Chicago: ['English'],
  Niagara: ['English'],
  London: ['English'],
  'Bay Area': ['English'],
  'New York': ['English'],
};

export const INTRODUCTORY_MESSAGE = 'The Rate Calculator tool and the information provided therein is Service Provider Background Technology which is confidential information of and proprietary to Accenture and must not be used for any purpose other than calculating the rates for Accenture services as contracted by Facebook in accordance with Attachment B: Global Rate Card for Service Provider Full Time Equivalents (FTEs) of Amendment 6, effective Janurary 27, 2022, to the Global Amendment to all Accenture Agreements with Facebook. This Rate Calculator tool and the information contained in it must not be disclosed to any person other than an employee of Facebook who requires the information in order to determine applicable Accenture rates.';

export const VERSION_MESSAGE = 'Current version 3.1.1';

export const LOADING_MESSAGE = 'Loading. Please wait.';

export const PER_ROW_REQUIRED = ['CommunicationTechnicalComplexityUplift', 'Role', 'Level', 'SubLevel', 'Language', 'Location'];

export const ALL_ROW_REQUIRED = ['CommunicationLevel', 'Desription', 'IncludeSupernova', 'IncludeWFM', 'ProgramName', 'Resiliency', 'TechnicalLevel', 'billingCurrency', 'calendarYear'];

export const BLENDED_ALL_ROW_REQUIRED = ['Location', 'Language', 'Overtime', 'NightShift', 'Weekend', 'PublicHoliday', 'FTECount'];

export const BLENDED_PER_ROW_REQUIRED = ['Role', 'Level', 'SubLevel', 'Billable', 'CommunicationTechnicalComplexityUplift', 'Ratio', 'FTECount'];

export const MONTHLY_RESOURCE_REQUIRED = ['FTECount', 'Overtime', 'NightShift', 'Weekend', 'PublicHoliday', 'StandardHourlyRatePerFTEFullValue'];

export function amazonHeader(token) {
  const AMAZON_API_HEADER_OBJECT = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    authorizationToken: token,
  };

  return AMAZON_API_HEADER_OBJECT;
}

export const CURRENCY_MAPPING = {
  USD: '$',
  INR: '₹',
  EUR: '€',
};

export const CONVERSION_COL_TO_REQUIRED = {
  Language: 'langCol',
  Role: 'roleCol',
  Level: 'levelCol',
  SubLevel: 'subLevelCol',
  CommunicationTechnicalComplexityUplift: 'includeComplexity',
  Location: 'locationCol',
};

export const CURRENT_COL_SHOW = {
  roleCol: true,
  levelCol: true,
  sublevelCol: true,
  locateCol: true,
  langCol: true,
  roleMonthCol: true,
  yearCol: true,
  BillHourCol: true,
  locaBaseCol: true,
  ComUpliftCol: true,
  langTieCol: true,
  resCol: true,
  superCol: true,
  wfmCol: true,
  standardFTECol: true,
};

export const loadingText = 'Please wait while loading';
export const errorText = 'You have not been granted access to this application. To gain access, please email meta.reporting@accenture.com';
export const supportText = 'Please send an email to meta.reporting@accenture.com for questions regarding rates, bugs, or improvement suggestions.';
export const updateText = 'Note: Rates have been changed to FY24 Rates as of March 11, 2024.';
export const loggedOutText = 'You have been successfully logged out';
