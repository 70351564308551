import React from 'react';
import _ from 'lodash';
import * as constantImports from './constants';

export function checkRateTrans(rateValue) {
  const numRate = Number(rateValue);
  return typeof numRate === 'number' && !Number.isNaN(numRate) && rateValue;
}

export function dateFormat(date, format) {
  const time = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'H+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds(),
  };
  if (/(y+)/.test(format)) format = format.replace(RegExp.$1, (`${date.getFullYear()}`).substr(4 - RegExp.$1.length));
  for (const k in time) {
    if (new RegExp(`(${k})`).test(format)) {
      format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? (time[k]) : ((`00${time[k]}`).substr((`${time[k]}`).length)));
    }
  }
  return format;
}

export function fetchExpires(days) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
    expires = `; expires=${date.toUTCString()}`;
  }
  return expires;
}

export function commafy(num) {
  const newNum = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return newNum;
}

export function formatDollarValue(number, currency, lastLetter) {
  let lastLetterToAssign = '';
  if (lastLetter) {
    lastLetterToAssign = lastLetter;
  }
  if (!number || number === 'null' || number === 'undefined' || number === 'false') {
    return '0';
  }
  if (Number.isNaN(Number(number))) {
    return number;
  }
  const numberToAssign = commafy((Math.round((Number(number) + Number.EPSILON) * 100) / 100).toFixed(2));
  return `${currency}${numberToAssign.toLocaleString()}${lastLetterToAssign}`;
}

export function getRandomNumber() {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  return array[0];
}

export function authenticateWithCognito(ENV) {
  const crypto = window.crypto || window.msCrypto;
  const array = new Uint32Array(1);
  crypto.getRandomValues(array);
  const GENERATED_NUM = array[0];

  if (ENV === 'production') {
    const BaseUrl = 'ratecard.accenture.com';
    window.location.replace(`https://login.microsoftonline.com/e0793d39-0939-496d-b129-198edd916feb/oauth2/v2.0/authorize?client_id=41e37e0b-7e15-44cc-a730-fcefb399f84d&response_type=id_token&redirect_uri=https://${BaseUrl}&scope=openid+profile&nonce=${GENERATED_NUM}`);
  } else if (ENV === 'staging') {
    const BaseUrl = 'staging.ratecard.accenture.com';
    window.location.replace(`https://login.microsoftonline.com/f3211d0e-125b-42c3-86db-322b19a65a22/oauth2/v2.0/authorize?client_id=edb081e8-b07b-4da1-95e4-363246620a83&response_type=id_token&redirect_uri=https://${BaseUrl}&scope=openid+profile&nonce=${
      GENERATED_NUM}`);
  }
}

export function changeCamelCaseToSentenceCase(textToChange) {
  const result = textToChange.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function setAll(obj, val) {
  Object.keys(obj).forEach((index) => {
    obj[index] = val;
  });
  return obj;
}

export function changeEmbed(pdfUrl, embedRef, idToken) {
  function pdfHandler() {
    if (this.readyState === this.DONE) {
      if (this.status === 200) {
        if (embedRef.current && embedRef.current.type) {
          embedRef.current.src = this.response.replace(/['"]+/g, '');
        }
      }
    }
  }

  const xhr = new XMLHttpRequest();
  xhr.open('GET', pdfUrl);
  xhr.setRequestHeader('authorizationToken', idToken);
  xhr.onreadystatechange = pdfHandler;
  xhr.send();
}

export function optionGenerateBusinessArea(userRoles, customRole, customOption) {
  return userRoles && (userRoles.includes(customRole) || userRoles.includes('FB_RATE_CALCULATOR_USERS')) && <option value={customOption}>{customOption}</option>;
}

export function lowerFirstLetter(string) {
  return string.charAt(0).toLowerCase() + string.slice(1);
}

export function compareCaseInsensitive(string1, string2) {
  return string1.toUpperCase() === string2.toUpperCase();
}

export function isArrayEqual(x, y) {
  return _(x).differenceWith(y, _.isEqual).isEmpty();
}

export function orderSelections(a, b) {
  const secondTernary = (a.indexSelection > b.indexSelection ? 1 : 0);
  return a.indexSelection < b.indexSelection ? -1 : secondTernary;
}

export function getLocationPerLevelSubLevelBusinessAreaAndComplexityAndExpertise(level, sublevel, businessArea, communicationWorkComplexity, domainTechExpertise, locationPerLevelSubLevelBusinessArea, complexityMultipliersByLocation) {
  let resultArray = [];
  const resultArrayFirst = [];
  for (let i = 2; i < locationPerLevelSubLevelBusinessArea.length; i += 1) {
    if (compareCaseInsensitive(locationPerLevelSubLevelBusinessArea[i][0], level)
      && compareCaseInsensitive(locationPerLevelSubLevelBusinessArea[i][1], sublevel)
      && compareCaseInsensitive(locationPerLevelSubLevelBusinessArea[i][3], businessArea)) {
      for (let k = 4; k < locationPerLevelSubLevelBusinessArea[i].length; k += 1) {
        if (locationPerLevelSubLevelBusinessArea[i][k] !== '') {
          resultArrayFirst.push(locationPerLevelSubLevelBusinessArea[0][k]);
        }
      }
    }
  }
  const resultArraySecond = [];
  if (communicationWorkComplexity && domainTechExpertise) {
    for (let i = 1; i < complexityMultipliersByLocation.length; i += 1) {
      if (compareCaseInsensitive(complexityMultipliersByLocation[i][0], communicationWorkComplexity)
        && compareCaseInsensitive(complexityMultipliersByLocation[i][1], domainTechExpertise)) {
        for (let k = 3; k < complexityMultipliersByLocation[i].length; k += 1) {
          if (complexityMultipliersByLocation[i][k] !== '') {
            resultArraySecond.push(complexityMultipliersByLocation[0][k]);
          }
        }
      }
    }
    resultArray = resultArrayFirst.filter((value) => resultArraySecond.includes(value));
  } else if (communicationWorkComplexity && !domainTechExpertise) {
    for (let i = 1; i < complexityMultipliersByLocation.length; i += 1) {
      if (compareCaseInsensitive(complexityMultipliersByLocation[i][0], communicationWorkComplexity)) {
        for (let k = 3; k < complexityMultipliersByLocation[i].length; k += 1) {
          if (complexityMultipliersByLocation[i][k] !== '') {
            resultArraySecond.push(complexityMultipliersByLocation[0][k]);
          }
        }
      }
    }
    resultArray = resultArrayFirst.filter((value) => resultArraySecond.includes(value));
  } else if (!communicationWorkComplexity && domainTechExpertise) {
    for (let i = 1; i < complexityMultipliersByLocation.length; i += 1) {
      if (compareCaseInsensitive(complexityMultipliersByLocation[i][1], domainTechExpertise)) {
        for (let k = 3; k < complexityMultipliersByLocation[i].length; k += 1) {
          if (complexityMultipliersByLocation[i][k] !== '') {
            resultArraySecond.push(complexityMultipliersByLocation[0][k]);
          }
        }
      }
    }
    resultArray = resultArrayFirst.filter((value) => resultArraySecond.includes(value));
  } else {
    resultArray = resultArrayFirst;
  }
  return resultArray;
}
